import { createSlice } from '@reduxjs/toolkit';

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState: [],
  reducers: {
    addBreadcrumb: (state, action) => {
      if (state.length > 1) {
        state.pop();
        state.push({ label: action.payload.label, url: action.payload.url });
      } else {
        state.push({ label: action.payload.label, url: action.payload.url });
      }
    },
    removeBreadcrumb: (state) => {
      if (state.length > 1) {
        state.pop();
      }
    },
  },
});

export const { addBreadcrumb, removeBreadcrumb } = breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
