import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './base';

const alertApi = createApi({
  reducerPath: 'alertApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    addAlert: builder.mutation({
      query: (payload) => ({
        url: `/v1/admin/alert`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useAddAlertMutation } = alertApi;

export default alertApi;
