import { CheckIcon, UserIcon, PhoneIcon } from '@heroicons/react/24/solid';
import moment from 'moment';

export enum Alert {
  SessionJoined = 3,
  CameraTestPassed = 26,
  NotificationPermissionGranted = 17,
  AudioDeviceDetected = 159,
  ScreenCaptureEnabled = 149,
  IdCardCaptureSuccess = 156,
  FaceCaptureSuccess = 154,
  TestEnvCaptureSuccess = 161,
  SecondaryCameraDisconnected = 190,
  SecondaryCameraConnected = 189,
  AudioCallEnded = 176,
  AudioCallProgress = 175,
  AudioCallInitiated = 173,
  AudioCallRequested = 172,
}

export const validAlerts = [
  Alert.SessionJoined,
  Alert.CameraTestPassed,
  Alert.NotificationPermissionGranted,
  Alert.AudioDeviceDetected,
  Alert.ScreenCaptureEnabled,
  Alert.IdCardCaptureSuccess,
  Alert.FaceCaptureSuccess,
  Alert.TestEnvCaptureSuccess,
  Alert.SecondaryCameraConnected,
  Alert.SecondaryCameraDisconnected,
  Alert.AudioCallEnded,
  Alert.AudioCallProgress,
  Alert.AudioCallInitiated,
  Alert.AudioCallRequested,
];

export const subset = [
  Alert.SessionJoined,
  Alert.CameraTestPassed,
  Alert.NotificationPermissionGranted,
  Alert.AudioDeviceDetected,
  Alert.ScreenCaptureEnabled,
  Alert.IdCardCaptureSuccess,
  Alert.FaceCaptureSuccess,
  Alert.TestEnvCaptureSuccess,
];

const getStepStatus = (alerts: number[], faceCaptureTimestamp: string, prechecks: any) => {
  console.log(alerts);
  const alertSubset = [];
  alerts.forEach((alert) => {
    if (subset.includes(alert)) alertSubset.push(alert);
  });
  let responseData = {
    secondaryCamera: null,
    chat: null,
    call: null,
    verification: null,
    testEnv: null,
    idCard: null,
    photo: null,
    audioTest: null,
    screenShare: null,
    notificationPermission: null,
    webcamPermission: null,
    microphonePermission: null,
  };
  const latestAlert = alertSubset[0];
  console.log('Latest Alert', latestAlert);
  let secondaryCameraConnectionStatus = <div className="text-red-700 font-bold text-xs">OFF</div>;
  let audioCallStatus = null;
  // Handle Secondary Camera Status
  for (let i = 0; i < alerts.length; i++) {
    if (alerts[i] === Alert.SecondaryCameraConnected) {
      secondaryCameraConnectionStatus = (
        <div className="text-emerald-700 font-bold text-xs">ON</div>
      );
      break;
    } else if (alerts[i] === Alert.SecondaryCameraDisconnected) {
      break;
    }
  }
  responseData.secondaryCamera = secondaryCameraConnectionStatus;
  // Handle Audio Call Status
  for (let i = 0; i < alerts.length; i++) {
    if (alerts[i] === Alert.AudioCallRequested) {
      audioCallStatus = <PhoneIcon className="h-5 w-5 text-red-700" />;
      break;
    } else if (alerts[i] === Alert.AudioCallEnded) {
      break;
    }
  }
  responseData.call = audioCallStatus;
  // Handle Test env status

  if (prechecks) {
    const keys = Object.keys(prechecks);
    const precheckList = [];
    keys.forEach((key, i) => {
      precheckList.push(prechecks[key]);
    });
    const testEnvData = precheckList.filter(
      (d) => moment(d.requested_timestamp) > moment(faceCaptureTimestamp) && d.step === 'test_env',
    );
    console.log(testEnvData);
    const res1 = {
      ...responseData,
      screenShare: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      photo: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      idCard: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      audioTest: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      microphonePermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      webcamPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      notificationPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      secondaryCamera: secondaryCameraConnectionStatus,
      call: audioCallStatus,
    };
    if (
      testEnvData &&
      testEnvData.length &&
      testEnvData[testEnvData.length - 1].status === 'approved'
    ) {
      console.log('Approved');
      responseData = {
        ...res1,
        testEnv: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
        verification: alerts.includes(Alert.ScreenCaptureEnabled) ? <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" /> : <UserIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      };
    } else if (
      testEnvData &&
      testEnvData.length &&
      testEnvData[testEnvData.length - 1].status === 'submitted'
    ) {
      console.log('Submitted');
      responseData = {
        ...res1,
        testEnv: <UserIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      };
    }
  } 


  if (latestAlert === Alert.CameraTestPassed) {
    return {
      ...responseData,
      microphonePermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      webcamPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      notificationPermission: <UserIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      secondaryCamera: secondaryCameraConnectionStatus,
      call: audioCallStatus,
    };
  } else if (latestAlert === Alert.AudioDeviceDetected) {
    return {
      ...responseData,
      audioTest: <UserIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      microphonePermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      webcamPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      notificationPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      secondaryCamera: secondaryCameraConnectionStatus,
      call: audioCallStatus,
    };
  } else if (latestAlert === Alert.IdCardCaptureSuccess) {
    return {
      ...responseData,
      idCard: responseData.testEnv ? (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ) : (
        <UserIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ),
      screenShare: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      audioTest: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      microphonePermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      webcamPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      notificationPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      secondaryCamera: secondaryCameraConnectionStatus,
      call: audioCallStatus,
    };
  } else if (latestAlert === Alert.FaceCaptureSuccess) {
    return {
      ...responseData,
      photo: responseData.testEnv ? (
        <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ) : (
        <UserIcon className="h-5 w-5 text-emerald-700 stroke-2" />
      ),
      screenShare: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      idCard: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      audioTest: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      microphonePermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      webcamPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      notificationPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      secondaryCamera: secondaryCameraConnectionStatus,
      call: audioCallStatus,
    };
  } else if (latestAlert === Alert.ScreenCaptureEnabled) {
    console.log('Screenshare');
    return {
      ...responseData,
      screenShare: <UserIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      microphonePermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      webcamPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      notificationPermission: <CheckIcon className="h-5 w-5 text-emerald-700 stroke-2" />,
      secondaryCamera: secondaryCameraConnectionStatus,
      call: audioCallStatus
    };
  }
  return null;
};

export default getStepStatus;
