import { useState } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from 'app/hooks';
import { useUpdatePrecheckMutation } from 'common/api/precheck';
import Modal from 'common/components/Modal';
import NotifyAttendee from 'common/components/NotifyAttendee';
import MediaPreviewModal from 'common/components/MediaPreviewModal';
import PrecheckActions from './PrecheckActions';
import VideoPreview from 'common/components/VideoPreview';
import ImagePreview from 'common/components/ImagePreview';
import { ActiveAttendee } from 'globals/interfaces';
import { ApprovalStatus, VerificationTypes } from '../../globals/enums';

type Props = {
  session_id: string;
  session_uuid: string;
  type: string;
  resourceUrl: string;
  timestamp: string;
  status: string;
  reason: string;
  faceCaptureUrl?: string;
};

const ApprovalItem = ({
  session_id,
  session_uuid,
  type,
  resourceUrl,
  timestamp,
  status,
  reason,
  faceCaptureUrl,
}: Props) => {
  const [showNotify, setShowNotify] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const attendee: ActiveAttendee = useAppSelector((state) => state.activeAttendee.attendee);
  const [patchApproval, { isLoading, isError }] = useUpdatePrecheckMutation();

  const updatePrecheck = async (status: string, reason?: string) => {
    const payload = {
      attendee_id: attendee.attendee_id,
      slot_id: attendee.slot_id,
      data: {
        verification_type: type,
        status,
        reason: reason || 'Empty Reason',
        session_id,
      },
    };
    await patchApproval(payload);
    setShowPreview(false);
  };

  const getMediaPreviewComponent = (): React.ReactNode => {
    return type === VerificationTypes.TestEnv ? (
      <VideoPreview session_id={session_id} session_uuid={session_uuid} path={resourceUrl} />
    ) : (
      <ImagePreview
        session_id={session_id}
        session_uuid={session_uuid}
        baseImageUrl={resourceUrl}
        faceCaptureUrl={faceCaptureUrl}
      />
    );
  };

  const getApprovalStatus = () => {
    const statusStyles = `rounded-full text-xs px-4 py-1 flex items-center gap-1 w-fit`;

    switch (status) {
      case ApprovalStatus.Approved:
        return (
          <span className={`bg-green-100 text-green-800 ${statusStyles}`}>
            <CheckCircleIcon className="h-3 w-3 inline" />
            <span>Approved</span>
          </span>
        );
      case ApprovalStatus.Rejected:
        return (
          <>
            <span className={`bg-red-100 text-red-600 ${statusStyles}`}>
              <XCircleIcon className="h-3 w-3" />
              <span>Rejected</span>
            </span>
            {reason && !reason.includes('Empty Reason') && <p className="text-xs">{reason}</p>}
          </>
        );
      case ApprovalStatus.Submitted:
        return (
          <PrecheckActions
            onNotify={() => setShowNotify(true)}
            onApproval={() => updatePrecheck(ApprovalStatus.Approved)}
            isLoading={isLoading}
          />
        );
      default:
        break;
    }
  };

  const handlePreviewNotify = () => {
    setShowPreview(false);
    setShowNotify(true);
  };

  return (
    <div className="py-2 flex flex-col gap-2">
      <span className="text-[11px] text-slate-400 relative top-2">
        {new Date(timestamp).toLocaleString().replace(',', ' |')}
      </span>
      <div data-testid="preview-wrapper" className="h-36" onClick={() => setShowPreview(true)}>
        {getMediaPreviewComponent()}
      </div>
      {getApprovalStatus()}
      {isError && <div className="text-sm text-red-500">Unable to update the status</div>}
      {status === ApprovalStatus.Submitted && (
        <>
          <Modal size="sm" show={showNotify} onCancel={() => setShowNotify(false)}>
            <NotifyAttendee
              type={type}
              onNotify={updatePrecheck}
              onClose={() => setShowNotify(false)}
            />
          </Modal>
          <Modal size="lg" show={showPreview} onCancel={() => setShowPreview(false)}>
            <MediaPreviewModal type={type}>
              {getMediaPreviewComponent()}
              <div className="ml-auto">
                <PrecheckActions
                  onNotify={() => handlePreviewNotify()}
                  onApproval={() => updatePrecheck(ApprovalStatus.Approved)}
                  isLoading={isLoading}
                />
              </div>
            </MediaPreviewModal>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ApprovalItem;
