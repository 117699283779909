import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Attendee } from 'common/api/proctorType';
import AttendeePane from 'common/components/AttendeePane';
import TabPane from 'common/components//TabPane';

import { SpeakerXMarkIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline';
import { updateGloablAudioSettings } from '../slice/audioSettingSlice';
import { isApprovalRequired } from 'helpers/prechecks';
import { Precheck } from 'globals/interfaces';
import { getAudioCallStatus, hasUnreadMessages } from 'helpers/messages';
import { mediaSettingsIcons } from './constant';
import { CurrentFeed, updateGloablMediaSettings } from 'common/slice/mediaSettingSlice';
import { SessionStatus } from 'globals/enums';

const RightPanel = ({ attendees, sessions }) => {
  const dispatch = useAppDispatch();
  const { attendeeId } = useAppSelector((state) => state.selectedAttendee);
  const { previousMuteSelection } = useAppSelector((state) => state.audioSettings);

  const [isAllAttendeeSelected, setIsAllAttendeeSelected] = useState(false);
  const [selectedAttendees, setSelectedAttendees] = useState<number[]>([]);

  const selectAllAttendee = () => {
    setIsAllAttendeeSelected(!isAllAttendeeSelected);
    setSelectedAttendees(
      isAllAttendeeSelected ? [] : attendees.map((item: Attendee) => item.attendee_id),
    );
  };

  const toggleAttendeeSelection = (attendeeId: number) => {
    const isSelected = selectedAttendees.includes(attendeeId);
    if (isSelected) {
      setSelectedAttendees(selectedAttendees.filter((selected) => selected !== attendeeId));
    } else {
      setSelectedAttendees([...selectedAttendees, attendeeId]);
    }
  };

  const handleAudioSettingChange = (audioStatus: boolean) => {
    const payload = {
      attendeeIds: isAllAttendeeSelected ? 'all' : selectedAttendees,
      audioStatus,
    };
    //@ts-ignore
    dispatch(updateGloablAudioSettings(payload));
  };

  const handleMediaSettingChange = (value) => {
    const payload = {
      attendeeIds: isAllAttendeeSelected ? 'all' : selectedAttendees,
      currentFeed: value as CurrentFeed,
    };
    //@ts-ignore
    dispatch(updateGloablMediaSettings(payload));
  };

  const renderMediaSettings = () => {
    return (
      <>
        {mediaSettingsIcons.map((icon, index) => (
          <icon.Icon
            key={index}
            title={icon.title}
            className={`h-4 w-4 cursor-pointer min-w-max`}
            onClick={() => handleMediaSettingChange(icon.value)}
          />
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-start gap-5 items-center h-10 min-h-40px">
        <input
          className="mr-2 ml-5 cursor-pointer"
          type="checkbox"
          onChange={selectAllAttendee}
          checked={isAllAttendeeSelected}
          data-testid="select-all-checkbox"
        />
        {!!selectedAttendees?.length && (
          <>
            {previousMuteSelection ? (
              <SpeakerXMarkIcon
                className="h-4 w-4 cursor-pointer"
                onClick={(event) => handleAudioSettingChange(!previousMuteSelection)}
              />
            ) : (
              <SpeakerWaveIcon
                className="h-4 w-4 cursor-pointer"
                onClick={(event) => handleAudioSettingChange(!previousMuteSelection)}
              />
            )}
            {renderMediaSettings()}
          </>
        )}
      </div>
      <div className="flex flex-row relative" style={{ height: 'calc(100% - 40px)' }}>
        <div className="w-2/5 pb-8 overflow-auto">
          {attendees?.map((attendee: Attendee, index: number) => {
            const session = sessions?.find((item) => item.attendee_id === attendee.attendee_id);
            const prechecks: Precheck[] = session?.pre_check
              ? Object.values(session.pre_check)
              : [];
            const isApprovalPending = isApprovalRequired(prechecks);
            const messages = session?.messages ? Object.values(session.messages) : [];
            const isChatUnread = hasUnreadMessages(messages);
            return (
              <span
                className={`${
                  attendeeId === attendee.attendee_id
                    ? 'bg-slate-100'
                    : session?.session_status === SessionStatus.NoShow
                    ? 'bg-red-500'
                    : ''
                } rounded-md flex items-center mx-2 justify-around h-10`}
                key={attendee.attendee_id}
              >
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  disabled={session?.session_status === SessionStatus.NoShow}
                  checked={selectedAttendees.includes(attendee.attendee_id)}
                  onChange={() => toggleAttendeeSelection(attendee.attendee_id)}
                  data-testid={attendee}
                />
                <AttendeePane
                  tooltipId={`list_tooltip_${index}`}
                  isApprovalPending={isApprovalPending}
                  attendee={attendee}
                  isChatUnread={isChatUnread}
                  sessionId={session?.session_id}
                  sessionUuid={session?.session_uuid}
                  sessionStatus={session?.session_status}
                />
              </span>
            );
          })}
        </div>
        <div className={`w-3/5 flex flex-col bg-slate-100 flex-grow h-full `}>
          <TabPane sessions={sessions} />
        </div>
      </div>
    </div>
  );
};

export default RightPanel;
