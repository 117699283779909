import { getSignedUrl } from 'helpers/prechecks';
import { useAppSelector } from 'app/hooks';

type Props = {
  baseImageUrl: string;
  faceCaptureUrl: string;
  session_uuid: string;
  session_id: string;
};

const ImagePreview = ({ baseImageUrl, faceCaptureUrl, session_uuid, session_id }: Props) => {
  const token = useAppSelector((state) => state.session.token)[session_id]?.value;

  return (
    token && (
      <div className="flex items-stretch gap-1 h-full max-h-96">
        <div className="flex-1 bg-slate-200">
          <img
            src={getSignedUrl(session_uuid, baseImageUrl, token)}
            alt="Face ID"
            className="h-full block my-0 mx-auto object-contain"
          />
        </div>
        <div className="flex-1">
          <img
            src={getSignedUrl(session_uuid, faceCaptureUrl, token)}
            alt="Face Verification"
            className="w-full h-full"
          />
        </div>
      </div>
    )
  );
};

export default ImagePreview;
