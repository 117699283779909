import LoaderImage from 'assets/loader.gif';

const Loader = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
      <div className="loader">
        <img src={LoaderImage} alt="loader" height="150px" width="150px" />
      </div>
    </div>
  );
};

export default Loader;
