import { ApolloClient, InMemoryCache, HttpLink, from, ApolloLink, concat } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { LocalStorage } from './LocalStorage';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(`GraphQL Error`, message);
    });
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${LocalStorage.getAccessToken()}`,
    },
  });
  return forward(operation);
});

const link = from([
  errorLink,
  new HttpLink({
    uri: process.env.GRAPH_API,
    headers: {
      'x-hasura-role': 'test-admin',
    },
  }),
]);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, link),
});
