import React from 'react';
import ApprovalTab from 'components/approvals/ApprovalTab';
import AlertTab from 'components/alerts/AlertTab';
import { TABS } from 'common/components/constant';
import { setActiveTab } from 'common/slice/activeTabSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ChatTab from 'components/chat/ChatTab';

const TabPane = ({ sessions }) => {
  const tabLabels = Object.values(TABS);
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector((state) => state.activeTab.tab);
  const attendee = useAppSelector((state) => state.activeAttendee.attendee);

  const handleTabSelection = (tabName: string) => {
    dispatch(setActiveTab(tabName));
  };

  const getTabButtonStyles = (tabLabel: string) => {
    const isActive = activeTab === tabLabel;
    const baseStyles = 'inline-block p-2 border-b-2 rounded-t-lg cursor-pointer';

    const activeStyles = `${baseStyles} text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500`;
    const inactiveStyles = `${baseStyles} hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`;

    return isActive ? activeStyles : inactiveStyles;
  };

  const getActiveTabComponent = (sessions): React.ReactNode => {
    const session = sessions?.find((item) => item.attendee_id === attendee.attendee_id);
    switch (activeTab) {
      case TABS.ALERT_LOGS:
        return (
          <AlertTab
            alerts={session?.alerts}
            sessionUuid={session?.session_uuid}
            sessionId={session?.session_id}
            attendeeId={session?.attendee_id}
            sessionStatus={session?.session_status}
          />
        );
      case TABS.CHAT:
        return <ChatTab session={session} />;
      case TABS.APPROVALS:
        return (
          <ApprovalTab
            session_uuid={session?.session_uuid}
            prechecks={session?.pre_check}
            session_id={session?.session_id}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className="flex flex-row justify-between px-2 h-10 items-center ">
        {tabLabels.map((tabLabel) => (
          <div
            key={tabLabel}
            onClick={() => handleTabSelection(tabLabel)}
            className={getTabButtonStyles(tabLabel)}
          >
            {tabLabel}
          </div>
        ))}
      </div>
      <hr className="w-full border-slate-400" />
      {getActiveTabComponent(sessions)}
    </>
  );
};

export default TabPane;
