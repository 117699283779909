import React from 'react';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/solid';

type Props = {
  message: any;
};

const UnreadMessage: React.FC<Props> = (props) => {
  if (!props.message) return null;
  const keys = Object.keys(props.message);
  let hasMessage = false;
  keys.forEach((key, i) => {
    if (props.message[key].read_at.length > 0) {
      hasMessage = true;
    }
  });
  return <ChatBubbleLeftRightIcon className="h-5 w-5 text-red-600" />;
};

export default UnreadMessage;
