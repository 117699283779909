import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import Button from 'common/components/Button';
import { SEVERITY } from 'common/components/constant';
import { formatDate } from 'helpers/date';
import { orderBy } from 'lodash';
import { useState } from 'react';

interface Props {
  isType: boolean;
  alertsList: any[];
}
const AlertList = ({ isType, alertsList }: Props) => {
  const [openAccordion, setOpenAccordion] = useState(null);
  const renderTypeGroup = () => {
    return (
      <div className="flex flex-col overflow-auto mb-2">
        {alertsList &&
          orderBy(alertsList, ['max_updated_time'], ['desc']).map((item: any) => (
            <div key={item.alert_type_id} className="px-3">
              <div
                onClick={() =>
                  setOpenAccordion(item.alert_type_id !== openAccordion ? item.alert_type_id : null)
                }
                className="flex cursor-pointer items-center text-sm mb-2 justify-between bg-slate-300 p-2"
              >
                <p>{isType ? item.description : item.severity}</p>
                <Button
                  className="text-slate-500"
                  icon={
                    openAccordion === item.alert_type_id ? (
                      <MinusCircleIcon className="h-5 w-5" />
                    ) : (
                      <PlusCircleIcon className="h-5 w-5" />
                    )
                  }
                />
              </div>
              {openAccordion === item.alert_type_id && (
                <div>
                  {orderBy(item.alerts, ['updated_at'], ['desc']).map((alert) => (
                    <div
                      key={alert.updated_at}
                      className="mb-2 flex rounded pl-1 pr-2 py-1 align-middle items-center border border-slate-300 bg-white"
                    >
                      <div
                        className="rounded w-1  h-6"
                        style={{ backgroundColor: SEVERITY[alert.severity] }}
                      ></div>
                      <div className="flex flex-col justify-start m-1 flex-grow text-xs">
                        <span> {formatDate(alert.updated_at)}</span>
                      </div>
                      <PlusCircleIcon className="w-6 h-6" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };

  const renderSeverityGroup = () => {
    return (
      <div className="flex flex-col overflow-auto mb-2">
        {alertsList?.map((item: any) => (
          <div key={`${item.severity}_sev`} className="px-3">
            <div
              onClick={() =>
                setOpenAccordion(item.severity !== openAccordion ? item.severity : null)
              }
              className="flex cursor-pointer items-center text-sm mb-2 justify-between bg-slate-300 p-2"
            >
              <p className="capitalize">{item.severity}</p>
              <Button
                className="text-slate-500"
                icon={
                  openAccordion === item.severity ? (
                    <MinusCircleIcon className="h-5 w-5" />
                  ) : (
                    <PlusCircleIcon className="h-5 w-5" />
                  )
                }
              />
            </div>
            {openAccordion === item.severity && (
              <div>
                {orderBy(item.alerts, ['updated_at'], ['desc']).map((alert) => (
                  <div
                    key={alert.updated_at}
                    className="mb-2 flex rounded pl-1 pr-2 py-1 align-middle items-center border border-slate-300 bg-white"
                  >
                    <div
                      className={`rounded w-1 h-12`}
                      style={{ backgroundColor: SEVERITY[alert.severity] }}
                    ></div>
                    <div className="flex flex-col justify-start m-1 flex-grow text-xs">
                      <span> {formatDate(alert.updated_at)}</span>
                      <span>{alert.description}</span>
                    </div>
                    <PlusCircleIcon className="w-6 h-6" />
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return isType ? renderTypeGroup() : renderSeverityGroup();
};
export default AlertList;
