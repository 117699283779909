import React from 'react';
import classNames from 'classnames';
import { Bars4Icon, ChartBarSquareIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { DashboardContext, ContextType as DashboardContextType } from 'contexts/DashboardContext';
import Option from './Option';

interface SidebarOption {
  icon: React.ReactNode;
  label: string;
}

const Sidebar: React.FC = () => {
  const { sidebarOpen, updateSidebarOpen } =
    React.useContext<DashboardContextType>(DashboardContext);
  const sidebarOptions: SidebarOption[] = [
    { label: 'Dashboard', icon: <ChartBarSquareIcon className="h-8 w-8" /> },
  ];
  const actionControlOptions: SidebarOption[] = [
    { label: 'Sign-out', icon: <UserCircleIcon className="h-8 w-8" /> },
  ];
  return (
    <div
      className={classNames(
        'flex flex-col min-h-full',
        sidebarOpen ? 'w-44' : 'w-[75px]',
        'border-r border-gray-200 border-t border-t-gray-100',
        'transition-[width] duration-150',
        'bg-white',
      )}
    >
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col p-3 space-y-10">
          <div
            className="text-gray-600 hover:cursor-pointer hover:text-gray-800"
            onClick={() => updateSidebarOpen(!sidebarOpen)}
          >
            <div className="flex items-center space-x-2">
              <Bars4Icon className="h-9 w-9" />
              {sidebarOpen && (
                <div className="flex flex-col items-baseline">
                  <p className="font-medium align-baseline leading-none">TALVIEW</p>
                  <p className="text-xs font-semibold align-top mt-0 leading-none text-emerald-500">
                    Proctoring
                  </p>
                </div>
              )}
            </div>
          </div>
          {}
          <div className="flex flex-col space-y-2">
            {sidebarOptions.map((sidebarOption) => (
              <Option
                key={sidebarOption.label}
                icon={sidebarOption.icon}
                label={sidebarOption.label}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col p-3 justify-end mb-10 space-y-2">
          {actionControlOptions.map((actionControlOption) => (
            <Option
              key={actionControlOption.label}
              icon={actionControlOption.icon}
              label={actionControlOption.label}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
