import { useEffect, useRef, useState } from 'react';
import MediaSettingsView from 'common/components/MediaSettings';
import { MediaSettings } from 'common/slice/mediaSettingSlice';
import { AudioSettings } from 'common/slice/audioSettingSlice';
import { useAppSelector } from 'app/hooks';
import TwilioVideoContainer from 'common/components/TwilioVideoContainer';
import { AUTO_ROTATE_INTERVAL } from 'common/components/constant';
import TileStatus from './TileStatus';
import { SessionStatus } from 'globals/enums';

const Tile = ({ sessionStatus, attendeeId, participant, isApprovalPending }) => {
  const mediaSettings: MediaSettings = useAppSelector((state) => state.mediaSettings);
  const audioSettings: AudioSettings = useAppSelector((state) => state.audioSettings);

  const participantMediaSetting = mediaSettings.mediaSettings.find(
    (a) => a.attendeeId === attendeeId,
  );

  const currentFeed = !participantMediaSetting
    ? mediaSettings.currentFeed
    : participantMediaSetting.currentFeed;

  const currentAutoRotateFeed = mediaSettings.currentAutoRotateFeed;

  const participantAudioSetting = audioSettings.audioSettings.find(
    (a) => a.attendeeId === attendeeId,
  );

  const currentAudioStatus = !participantAudioSetting
    ? audioSettings.isMuted
    : participantAudioSetting.isMuted;

  return (
    <>
      {(isApprovalPending ||
        sessionStatus === SessionStatus.Completed ||
        sessionStatus === SessionStatus.Terminated ||
        !participant) && <TileStatus type={sessionStatus} isApprovalPending={isApprovalPending} />}
      {participant &&
        sessionStatus !== SessionStatus.Completed &&
        sessionStatus !== SessionStatus.Terminated && (
          <TwilioVideoContainer
            attendeeId={attendeeId}
            participant={participant}
            currentAudioStatus={currentAudioStatus}
            currentAutoFeed={currentFeed === 'auto-rotate' ? currentAutoRotateFeed : currentFeed}
            isApprovalPending={isApprovalPending}
          />
        )}
      {participant && <MediaSettingsView attendeeId={attendeeId} currentFeed={currentFeed} />}
    </>
  );
};

export default Tile;
