import React, { useContext, useState } from 'react';
import Checkbox from 'common/components/Checkbox';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import EmptyState from 'common/components/EmptyState';
import { UserCircleIcon } from '@heroicons/react/24/outline';

const ProctorList: React.FC = () => {
  const { proctors, setProctors } = useContext<DashboardContextType>(DashboardContext);
  if (!proctors || !proctors.length) {
    return <EmptyState description="No data found" icon={<UserCircleIcon className="h-5 w-5" />} />;
  }

  const handleProctorSelection = (event: React.ChangeEvent<HTMLInputElement>, userId: number) => {
    const data = proctors.map((proctor) => {
      if (proctor.user_id === userId) {
        proctor.selected = event.target.checked;
      }
      return proctor;
    });
    console.log('@@@@@@@@', data);
    setProctors(data);
  };

  return (
    <div className="flex flex-col">
      {proctors.map((proctor) => (
        <div
          key={proctor.id}
          className="flex flex-row justify-between items-center w-full p-1 hover:cursor-pointer"
        >
          <div className="flex flex-col w-full">
            <div className="text-[12px] font-semibold">{proctor.username}</div>
            <div className="text-[10px]">{/* abc */}</div>
          </div>
          <Checkbox
            id={proctor.id}
            name={proctor.username}
            size="sm"
            onChange={(event) => handleProctorSelection(event, proctor.user_id)}
            checked={proctor.selected}
            disabled={false}
          />
        </div>
      ))}
    </div>
  );
};

export default ProctorList;
