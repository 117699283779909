import Accordion from 'common/components/Accordion';
import ApprovalItem from './ApprovalItem';
import { Precheck } from 'globals/interfaces';

type Props = {
  session_id: string;
  session_uuid: string;
  prechecks: {
    idChecks: Precheck[];
    envChecks: Precheck[];
  };
};

const PrecheckWrapper = ({
  session_id,
  session_uuid,
  prechecks: { idChecks, envChecks },
}: Props) => {
  return (
    <div>
      {idChecks?.length > 0 && (
        <Accordion title="Identity Check">
          {idChecks?.map((precheck) => (
            <ApprovalItem
              session_id={session_id}
              session_uuid={session_uuid}
              key={precheck.uuid}
              type={precheck.step}
              resourceUrl={precheck.file_path}
              timestamp={precheck.requested_timestamp}
              status={precheck.status}
              reason={precheck.reason}
              faceCaptureUrl={precheck.faceCaptureUrl}
            />
          ))}
        </Accordion>
      )}

      {envChecks?.length > 0 && (
        <Accordion title="Environment Check">
          {envChecks.map((precheck) => (
            <ApprovalItem
              session_id={session_id}
              session_uuid={session_uuid}
              key={precheck.uuid}
              type={precheck.step}
              resourceUrl={precheck.file_path}
              timestamp={precheck.requested_timestamp}
              status={precheck.status}
              reason={precheck.reason}
            />
          ))}
        </Accordion>
      )}
    </div>
  );
};

export default PrecheckWrapper;
