import { useAppDispatch } from 'app/hooks';
import { useUpdateStatusMutation } from 'common/api/session';
import { closeModal } from 'common/slice/modalSlice';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { SessionStatus } from 'globals/enums';

interface Props {
  sessionId: string;
}

const TerminateModal = ({ sessionId }: Props) => {
  const dispatch = useAppDispatch();
  const [updateStatus] = useUpdateStatusMutation();

  const updateSessionStatus = async () => {
    dispatch(closeModal({}));
    await updateStatus({
      session_id: sessionId,
      status: SessionStatus.Terminated,
    });
  };

  return (
    <div className="flex gap-2">
      <span className="w-10 h-10 bg-red-200 text-red-600 rounded-full flex p-2">
        <XCircleIcon />
      </span>
      <div className="flex-1 flex flex-col mt-1.5">
        <p className="font-bold text-lg">Terminate Candidate?</p>
        <p className="mb-4 text-zinc-500">You are terminating this candidate from this session</p>
        <div className="ml-auto flex gap-1 text-sm">
          <button className="secondary-btn" onClick={() => dispatch(closeModal({}))}>
            Cancel
          </button>
          <button className="primary-btn" onClick={updateSessionStatus}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default TerminateModal;
