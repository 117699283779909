import { useEffect, useRef, useState } from 'react';
import { RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import AudioSetting from './AudioSetting';

interface Props {
  attendeeId: number;
  participant: any;
  currentAutoFeed: string;
  currentAudioStatus: boolean;
  isApprovalPending: boolean;
}
const TwilioVideoContainer = ({
  attendeeId,
  currentAutoFeed,
  participant,
  currentAudioStatus,
  isApprovalPending,
}: Props) => {
  const [isVideoTrackAvailable, setIsVideoTrackAvailable] = useState(true);
  const [isAudioAvailable, setIsAudioAvailable] = useState(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const { primary, secondary } = participant;

  useEffect(() => {
    let videoTrackToAttach: RemoteVideoTrack | null = null;

    if (currentAutoFeed === 'primary') {
      videoTrackToAttach = primary.tracks.find((track: RemoteVideoTrack) => {
        return track.kind === 'video' && track.name !== 'screenshare';
      });
    } else if (currentAutoFeed === 'secondary') {
      videoTrackToAttach = secondary.tracks.find((track: RemoteVideoTrack) => {
        return track.kind === 'video';
      });
    } else {
      videoTrackToAttach = primary.tracks.find((track: RemoteVideoTrack) => {
        return track.kind === 'video' && track.name === 'screenshare';
      });
    }

    if (videoTrackToAttach) {
      if (videoRef.current) {
        setIsVideoTrackAvailable(true);
        videoTrackToAttach.attach(videoRef.current);
      }
    } else {
      videoRef.current.srcObject = null;
      setIsVideoTrackAvailable(false);
    }

    const audioTrack = primary.tracks.find((track: RemoteAudioTrack) => {
      return track.kind === 'audio';
    });

    if (audioTrack && audioRef.current) {
      setIsAudioAvailable(true);
      audioTrack.attach(audioRef.current);
    } else {
      setIsAudioAvailable(false);
    }
  }, [primary.tracks.length, secondary.tracks.length, currentAutoFeed]);

  return (
    <div
      data-testid="twilio-video-container"
      className="flex relative flex-col p-2 h-full flex-grow justify-center items-center"
    >
      <video className="w-full h-full" ref={videoRef} autoPlay muted playsInline></video>
      <audio ref={audioRef} autoPlay muted={currentAudioStatus} />
      {isAudioAvailable && (
        <AudioSetting currentAudioStatus={currentAudioStatus} attendeeId={attendeeId} />
      )}
      {!isVideoTrackAvailable && !isApprovalPending && (
        <div className="text-sm flex flex-col absolute gap-1 items-center text-white">
          <ExclamationTriangleIcon className="h-10 w-10" />
          <span>No Feed</span>
        </div>
      )}
    </div>
  );
};

export default TwilioVideoContainer;
