import React, { useContext, useEffect, useState } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  flexRender,
} from '@tanstack/react-table';
import { GET_PARTICIPANT_DETAILS } from 'components/dashboard/queries.graphql';
import { Participant } from 'components/dashboard/dashboard/MonitoringBoard';
import { USER_ROLES } from 'common/components/constant';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import { useQuery } from '@apollo/client';
import Loading from 'common/components/Loading';
import EmptyState from 'common/components/EmptyState';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { columns, Data } from './TableAttributes';
import { CheckIcon, UserIcon } from '@heroicons/react/24/solid';
import { cloneDeep, orderBy } from 'lodash';
import moment from 'moment';
import UnreadMessage from './UnreadMessage';
import TestEnvCell from './TestEnvCell';
import VerificationStatus from './VerificationStatus';
import getStepStatus, { Alert, validAlerts } from './getStepStatus';

interface User {
  email: string;
  first_name: string;
  last_name: string;
  id: number;
  user_id: number;
}
interface Candidate extends User {
  external_attendee_id: string;
}

interface Proctor extends User {
  username: string;
  selected?: boolean;
}

interface ParticipantResponse {
  attendee: Candidate[];
  views_user: Proctor[];
}

interface ParticipantDetailsRequest {
  proctorIds: number[];
  attendeeIds: number[];
}

type Props = {
  participants: Participant[];
};

const AttendeeStepTable: React.FC<Props> = (props) => {
  const {
    sessions,
    setProctors,
    proctors: proctorList,
  } = useContext<DashboardContextType>(DashboardContext);
  const attendeeIds = props.participants
    .filter((participant) => participant.role === USER_ROLES.CANDIDATE)
    .map((user) => user.user_id);
  const proctorIds = props.participants
    .filter((participant) => participant.role === USER_ROLES.PROCTOR)
    .map((user) => user.user_id);

  const {
    loading,
    data: participantData,
    error,
  } = useQuery<ParticipantResponse, ParticipantDetailsRequest>(GET_PARTICIPANT_DETAILS, {
    variables: {
      proctorIds,
      attendeeIds,
    },
  });

  const [tableData, setTableData] = useState<Data[]>([]);
  const [tableDataBackup, setTableDataBackup] = useState<Data[]>([]);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  React.useEffect(() => {
    if (participantData && sessions && sessions.length) {
      const items = cloneDeep(participantData);
      items.views_user.forEach((user) => {
        user.selected = false;
      });
      setProctors(items.views_user);
      const result: Data[] = [];
      sessions.forEach((session) => {
        const proctors = props.participants.filter(
          (p) => p.participant_group_id === session.participant_group_id && p.role === 'proctor',
        );
        const candidates = props.participants.filter(
          (p) => p.participant_group_id === session.participant_group_id && p.role === 'candidate',
        );
        if (proctors && proctors.length && candidates && candidates.length && session.alerts) {
          const proctorDetails = participantData.views_user.find(
            (p) => p.id === proctors[0].user_id,
          );
          const candidateDetails = participantData.attendee.find(
            (a) => a.id === session.attendee_id,
          );
          let alerts: any[] = [];
          const keys = Object.keys(session.alerts);
          keys.forEach((key, i) => {
            alerts.push({
              alert_type_id: session.alerts[key].alert_type_id,
              timestamp: session.alerts[key].updated_at,
            });
          });

          alerts = orderBy(
            alerts,
            (o) => {
              return moment(o.timestamp);
            },
            ['desc'],
          );

          const alertIds = [];

          let faceCaptureTimestamp = null;
          let audioDeviceDetectedTimestamp = null;
          const alertMap: Record<number, boolean> = {};
          let i = 0;
          while (i <= alerts.length) {
            if (!alertMap[alerts[i].alert_type_id]) {
              if (alerts[i].alert_type_id === Alert.SessionJoined) {
                alertIds.push(alerts[i].alert_type_id);
                break;
              }
              if (alerts[i].alert_type_id === Alert.FaceCaptureSuccess) {
                faceCaptureTimestamp = alerts[i].timestamp;
              }
              if (alerts[i].alert_type_id === Alert.AudioDeviceDetected) {
                audioDeviceDetectedTimestamp = alerts[i].timestamp;
              }
              if (validAlerts.includes(alerts[i].alert_type_id)) {
                alertIds.push(alerts[i].alert_type_id);
              }
              alertMap[alerts[i].alert_type_id] = true;
            }
            i++;
          }
          console.log(session);
          const stepStatus = getStepStatus(alertIds, faceCaptureTimestamp, session.pre_check);
          console.log(stepStatus);

          result.push({
            ...stepStatus,
            id: session.id,
            slotId: session.slot_id,
            status: session.session_status,
            proctor: proctorDetails.email,
            proctorUserId: proctorDetails.user_id,
            attendee: candidateDetails.external_attendee_id,
            attendeeId: candidateDetails.id,
            participantGroupId: session.participant_group_id,
            primaryCamera: (
              <div
                className={`${
                  session.session_status === 'online'
                    ? 'text-emerald-700 font-bold'
                    : 'text-red-700 font-bold'
                }`}
              >
                {session.session_status === 'online' ? 'ON' : 'OFF'}
              </div>
            ),
            chat: <UnreadMessage message={session.messages} />,
          });
        }
      });
      setTableData(result);
      setTableDataBackup(result);
    }
  }, [participantData, sessions]);

  useEffect(() => {
    const selectedProctors = proctorList.filter((p) => p.selected === true);
    if (selectedProctors.length) {
      const filteredProctors = tableDataBackup.filter((d) => proctorList.includes(d.proctorUserId));
      setTableData(filteredProctors);
    } else {
      setTableData(tableDataBackup);
    }
  }, [proctorList]);

  if (loading) return <Loading />;
  if (!sessions) return <EmptyState description="No data yet" icon={<CalendarDaysIcon />} />;

  return (
    <table className="min-w-max text-xs h-full">
      <thead className="border-b border-slate-200">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            className="[&>*:nth-child(1)]:border-r [&>*:nth-child(1)]:border-slate-200 [&>*:nth-child(1)]:w-1/6 [&>*:nth-child(2)]:w-1/6"
          >
            {headerGroup.headers.map((header) => (
              <th key={header.id} className="text-start p-2 px-4">
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className="[&>*:nth-child(1)]:border-r [&>*:nth-child(1)]:border-slate-200 [&>*:nth-child(1)]:w-1/6 [&>*:nth-child(2)]:w-1/6"
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="p-2 px-4 break-normal">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AttendeeStepTable;
