import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import jwtDecoder from 'jwt-decode';
import { setCurrentUser } from 'common/slice/currentUserSlice';

interface User {
  user: {
    id: number;
    email: string;
    external_id?: string;
    organization_id: number;
    username: string;
  };
}

const ProtectedRoute = () => {
  const dispatch = useAppDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const stored = localStorage.getItem('live-proctor-session');
    const { access_token } = stored ? JSON.parse(stored) : { access_token: null };
    if (access_token) {
      const { user }: User = jwtDecoder(access_token);
      const currentUser = {
        id: user.id,
        email: user.email,
        external_id: user.external_id,
        organization_id: user.organization_id,
        username: user.username,
      };
      setIsAuthenticated(true);
      dispatch(setCurrentUser(currentUser));
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return isAuthenticated !== null && (isAuthenticated ? <Outlet /> : <Navigate to="auth-error" />);
};

export default ProtectedRoute;
