import { useCreateStreamMutation } from 'common/api/proctor';
import { handleError } from 'helpers/sentry';
import { useEffect, useState } from 'react';
import Video, { RemoteTrack } from 'twilio-video';

export interface ParticipantData {
  session_id: string;
  primary: {
    tracks: RemoteTrack[];
  };
  secondary: {
    tracks: RemoteTrack[];
  };
}

const useTwilioStream = (participant_group_id) => {
  const [createStream] = useCreateStreamMutation();
  const [participants, setParticipants] = useState<ParticipantData[]>([]);

  const addParticipant = (participant: Video.RemoteParticipant, track: Video.RemoteTrack) => {
    const [name, session_id, type] = participant.identity.split('_');
    if (name !== 'host') {
      //@ts-ignore
      setParticipants((prev) => {
        const existingParticipantIndex = prev.findIndex((p) => p.session_id === session_id);
        if (existingParticipantIndex !== -1) {
          const updatedParticipants = [...prev];
          const existingParticipant = updatedParticipants[existingParticipantIndex];

          if (type === 'primary') {
            existingParticipant.primary.tracks.push(track);
          } else if (type === 'secondary') {
            existingParticipant.secondary.tracks.push(track);
          }
          return updatedParticipants;
        } else {
          const newParticipant = {
            session_id,
            primary: {
              tracks: type === 'primary' ? [track] : [],
            },
            secondary: {
              tracks: type === 'secondary' ? [track] : [],
            },
          };
          return [...prev, newParticipant];
        }
      });
    }
  };

  const updateParticipant = (participant: Video.RemoteParticipant, track: Video.RemoteTrack) => {
    const [id, session_id, type] = participant.identity.split('_');
    setParticipants((prev) => {
      const existingParticipantIndex = prev.findIndex((p) => p.session_id === session_id);
      if (existingParticipantIndex !== -1) {
        const updatedParticipants = [...prev];
        const existingParticipant = updatedParticipants[existingParticipantIndex];
        if (type === 'primary') {
          const { name, kind } = track;
          if (name === 'screenshare') {
            const tracks = existingParticipant.primary.tracks.filter(
              (item) => item.name !== 'screenshare',
            );
            existingParticipant.primary.tracks = tracks;
          } else if (kind === 'video') {
            const tracks = existingParticipant.primary.tracks.filter(
              (item) => item.kind !== 'video' && item.name !== 'screenshare',
            );
            existingParticipant.primary.tracks = tracks;
          } else {
            const tracks = existingParticipant.primary.tracks.filter(
              (item) => item.kind !== 'audio',
            );
            existingParticipant.primary.tracks = tracks;
          }
        } else {
          const tracks = existingParticipant.secondary.tracks.filter(
            (item) => item.kind !== 'video',
          );
          existingParticipant.secondary.tracks = tracks;
        }
        return updatedParticipants;
      }
      return prev;
    });
  };

  useEffect(() => {
    if (participant_group_id) {
      const initializeTwilio = async () => {
        try {
          const twilioStream = await createStream({
            participant_group_id,
          }).unwrap();

          const room = await Video.connect(twilioStream.token, {
            audio: false,
            video: false,
          });

          room.participants.forEach((participant) => {
            participant.on('trackSubscribed', (track) => {
              addParticipant(participant, track);
            });
            participant.on('trackUnsubscribed', (track) => {
              updateParticipant(participant, track);
            });
          });

          room.on('participantConnected', (participant) => {
            participant.tracks.forEach((publication) => {
              if (publication.isSubscribed) {
                const track = publication.track;
                addParticipant(participant, track);
              }
            });

            participant.on('trackSubscribed', (track) => {
              addParticipant(participant, track);
            });

            participant.on('trackUnsubscribed', (track) => {
              updateParticipant(participant, track);
            });
          });
        } catch (error) {
          handleError(error);
        }
      };

      initializeTwilio();
    }
  }, [participant_group_id]);

  return participants;
};

export default useTwilioStream;
