import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  isOpen: false,
  attendeeId: null,
  sessionUuid: '',
  sessionId: '',
};

const modalSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        attendeeId: number;
        type: string;
        sessionUuid: string;
        sessionId: string;
      }>,
    ) => {
      const { attendeeId, type, sessionUuid, sessionId } = action.payload;
      return { ...state, attendeeId, type, isOpen: true, sessionUuid, sessionId };
    },
    closeModal: (state, action) => {
      return initialState;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
