import { orderBy } from 'lodash';

const getGroupedAlerts = (alerts, isType: boolean, search: string) => {
  const groupedData = {};

  const severityOrder = ['critical', 'high', 'medium', 'low', 'none'];

  for (const key in alerts) {
    if (alerts.hasOwnProperty(key)) {
      const item = alerts[key];
      const { alert_type_id, updated_at, description, severity } = item;

      if (search) {
        const searchLower = search?.toLowerCase();
        if (
          !severity?.toLowerCase().includes(searchLower) &&
          !description?.toLowerCase().includes(searchLower)
        ) {
          continue;
        }
      }

      let groupKey;
      if (isType) {
        groupKey = alert_type_id;
      } else {
        groupKey = severity;
      }

      if (!groupedData[groupKey]) {
        groupedData[groupKey] = {
          max_updated_time: updated_at,
          items: [item],
          description: description,
          severity: severity,
        };
      } else {
        if (updated_at > groupedData[groupKey].max_updated_time) {
          groupedData[groupKey].max_updated_time = updated_at;
        }

        groupedData[groupKey].items.push(item);
      }
    }
  }

  const groupedArray = Object.keys(groupedData).map((groupKey) => ({
    alert_type_id: isType ? parseInt(groupKey, 10) : undefined,
    severity: groupedData[groupKey].severity,
    max_updated_time: groupedData[groupKey].max_updated_time,
    description: groupedData[groupKey].description,
    alerts: groupedData[groupKey].items,
  }));

  const sortedData = orderBy(
    groupedArray,
    (item) => (isType ? -item.max_updated_time : severityOrder.indexOf(item.severity)),
    isType ? 'desc' : 'asc',
  );

  return sortedData;
};

export default getGroupedAlerts;
