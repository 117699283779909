import { useState, useRef, useEffect, memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from 'app/hooks';
import { handleError } from 'helpers/sentry';
import {
  createNewMessage,
  getDeliveredStatusWithDateTime,
  markCandidateMessagesAsReadInFirebase,
} from 'helpers/messages';
import { USER_ROLES } from 'common/components/constant';
import { isSessionEnded } from 'helpers/session';

interface Props {
  messages: any[];
  session_id: string;
  session_status: string;
}

const ChatWindow = memo(({ messages, session_id, session_status }: Props) => {
  const [inputText, setInputText] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { id } = useAppSelector((state) => state.currentUser);

  const messagesUpdate = () => {
    if (messages) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
      markCandidateMessagesAsReadInFirebase(session_id, messages);
    }
  };

  useEffect(messagesUpdate, [messages]);

  const handleSendMessage = async () => {
    if (inputText.trim() !== '') {
      try {
        const messageData = {
          created_at: new Date().toISOString(),
          id: uuidv4(),
          user_id: id,
          read_at: '',
          received_at: '',
          role: 'proctor',
          text: inputText,
          updated_at: '',
        };
        await createNewMessage(session_id, messageData);
        setInputText('');
      } catch (error) {
        handleError(error);
      }
    }
  };

  const renderMessages = () => {
    const groupedMessages = [];
    let currentUserRole = null;

    const chatMessages = messages ? Object.values(messages) : [];

    chatMessages.forEach((message) => {
      if (currentUserRole !== message.role) {
        groupedMessages.push({ role: message.role, messages: [message] });
        currentUserRole = message.role;
      } else {
        groupedMessages[groupedMessages.length - 1].messages.push(message);
      }
    });

    return groupedMessages.map((group, index) => (
      <div
        key={index}
        className={`flex ${group.role === USER_ROLES.CANDIDATE ? 'justify-start' : 'justify-end'}`}
      >
        <div className="flex mb-2 gap-2">
          <div className={`${group.role === USER_ROLES.CANDIDATE ? 'order-first' : 'order-last'}`}>
            <span
              className={`flex justify-center items-center h-6 w-6  text-xs rounded-full border shadow border-solid ${
                group.role === USER_ROLES.CANDIDATE ? 'bg-white' : 'bg-orange-500 text-white'
              }`}
            >
              {group.role === USER_ROLES.CANDIDATE ? 'C' : 'P'}
            </span>
          </div>
          <div className="flex flex-col">
            {group.messages.map((message: any, i: number) => (
              <div
                key={`${i}_msg`}
                className={`${
                  group.role === USER_ROLES.CANDIDATE ? 'justify-start' : 'justify-end'
                } flex flex-col w-full  mb-2`}
              >
                <div
                  className={`${
                    group.role === USER_ROLES.CANDIDATE ? 'justify-start' : 'justify-end'
                  } inline-flex flex-wrap mb-2`}
                >
                  <span
                    className={`${
                      group.role === USER_ROLES.CANDIDATE ? 'bg-white' : 'bg-blue-500 text-white'
                    } border shadow border-solid p-1 border-gray-300 rounded max-w-full whitespace-normal break-all`}
                  >
                    {message.text}
                  </span>
                </div>
                <div
                  className={`flex text-8 text-gray-500  ${
                    group.role === 'candidate' ? 'justify-start' : 'justify-end'
                  } leading-3`}
                >
                  <span> {getDeliveredStatusWithDateTime(message)} </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    ));
  };

  const isSessionOver = isSessionEnded(session_status);

  return (
    <>
      <div
        className="flex flex-col flex-grow overflow-y-auto"
        style={{ maxHeight: 'calc(100% - 92px)' }}
      >
        {renderMessages()}
        <div data-testid="messages-end-ref" ref={messagesEndRef}></div>
      </div>
      <div className="flex h-10 gap-2 items-center justify-center">
        <input
          type="text"
          className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSendMessage();
            }
          }}
          disabled={isSessionOver}
        />
        {isSessionOver ? (
          <ArrowRightCircleIcon data-testid="send-message" className="h-8 w-8 fill-gray-300" />
        ) : (
          <ArrowRightCircleIcon
            data-testid="send-message"
            className="h-8 w-8 fill-green-300 cursor-pointer"
            onClick={() => handleSendMessage()}
          />
        )}
      </div>
    </>
  );
});

export default ChatWindow;
