export enum VerificationTypes {
  TestEnv = 'test_env',
  PhotoId = 'photo_id',
  FaceCapture = 'face_capture',
}

export enum ApprovalStatus {
  Pending = 'pending',
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  Stale = 'stale',
}

export enum TwilioCallStatus {
  Requested = 'requested',
  Initiated = 'initiated',
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export enum TwilioCallEvents {
  Error = 'error',
  TokenWillExpire = 'tokenWillExpire',
  Ringing = 'ringing',
  Accept = 'accept',
  Disconnect = 'disconnect',
}

export enum SessionStatus {
  NoShow = 'no_show',
  Terminated = 'terminated',
  Completed = 'completed',
  Online = 'online',
}
