import React from 'react';
import { Outlet } from 'react-router-dom';
import { startCase } from 'lodash';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import Sidebar from '../sidebar/Sidebar';

const Dashboard = () => {
  const { selectedOption } = React.useContext<DashboardContextType>(DashboardContext);
  const currentDate = new Date().toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });

  return (
    <div className="flex h-screen text-gray-600">
      <Sidebar />
      <div className="flex flex-1 flex-col px-[20px] py-[18px] bg-gray-100 overflow-hidden">
        <div className="flex space-x-2 items-center">
          <div className="text-base font-medium text-gray-600">{startCase(selectedOption)}</div>
          <CalendarIcon className="h-4 w-4" />
          <div className="text-xs font-normal">{currentDate}</div>
        </div>
        <div className="mt-3 h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
