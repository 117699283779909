/* eslint-disable no-undef */
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: 'production',
    integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    release: process.env.SENTRY_RELEASE,
  });
}

if (process.env.NODE_ENV === 'development' && process.env.MSW_ENABLED == 'true') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./msw/browser');
  worker.start().then(() => createRoot(document.getElementById('root')).render(<App />));
} else {
  createRoot(document.getElementById('root')).render(<App />);
}
