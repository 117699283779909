import { useAppSelector } from 'app/hooks';
import { getSignedUrl } from 'helpers/prechecks';

type Props = {
  path: string;
  session_uuid: string;
  session_id: string;
};

const VideoPreview = ({ path, session_id, session_uuid }: Props) => {
  const token = useAppSelector((state) => state.session.token)[session_id]?.value;

  return (
    token && (
      <div className="group relative h-full">
        <video
          controls
          src={getSignedUrl(session_uuid, path, token)}
          className="w-full h-full bg-black max-h-[500px]"
        />
      </div>
    )
  );
};

export default VideoPreview;
