import React, { useEffect, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_SLOTS } from '../queries.graphql';
import moment from 'moment';
import Element from './Element';
import Slot from 'components/dashboard/dashboard/events/slots';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import Slots from 'components/dashboard/dashboard/events/slots';
import AttendeeStatus from 'components/dashboard/dashboard/attendeeStatus/AttendeeStatus';
import { countBy } from 'lodash';
import AttendeeStepTable from 'components/dashboard/dashboard/attendeeStepTable/AttendeeStepTable';
import ProctorList from './proctorsList/ProctorList';

export interface Participant {
  id: number;
  participant_group_id: number;
  role: string;
  user_id: number;
}
export interface Slot {
  event: {
    id: number;
    name: string;
  };
  participant: Participant[];
  starts_at: string;
  ends_at: string;
  id: number;
  selected: boolean;
}
interface SlotView {
  views_slot: Slot[];
}

export interface SlotRequestArgument {
  startsAt: string;
  endsAt: string;
}

const MonitoringBoard: React.FC = () => {
  const { updateSlots, selectedSlot, updateSelectedSlot, slots } =
    useContext<DashboardContextType>(DashboardContext);
  const { loading, error, data } = useQuery<SlotView, SlotRequestArgument>(GET_SLOTS, {
    variables: {
      startsAt: moment().startOf('day').toISOString(),
      endsAt: moment().endOf('day').toISOString(),
    },
  });

  React.useEffect(() => {
    if (data && data.views_slot.length) {
      const updatedSlots = data.views_slot.map((slot) => ({ ...slot, selected: false }));
      updatedSlots[0].selected = true;
      updateSlots(updatedSlots);
      updateSelectedSlot(updatedSlots[0].id);
    }
  }, [data]);

  const selectedSlotData = slots.find((slot) => slot.id === selectedSlot);
  const numbOfCandidates = countBy(selectedSlotData?.participant, 'role')['candidate'] || 0;

  return (
    <div className="flex flex-col w-full h-full space-y-3 text-gray">
      <div className="flex w-full h-1/3 space-x-3">
        <div className="flex w-1/5">
          <Element header="Events" loading={loading} emptyMessage="No events yet">
            <Slots />
          </Element>
        </div>
        <div className="flex w-2/5">
          <Element
            header={`Attendee Status (${numbOfCandidates} Invited)`}
            loading={loading}
            emptyMessage="No status yet"
          >
            {selectedSlot && <AttendeeStatus invited={numbOfCandidates} />}
          </Element>
        </div>
        <div className="flex w-2/5">
          <Element header="Not Connected" loading={false} emptyMessage="No events yet"></Element>
        </div>
      </div>
      <div className="flex flex-row w-full h-2/3 space-x-3">
        <div className="flex flex-col w-1/5 space-y-3">
          <Element header="Proctors Offline" loading={false} emptyMessage="No events yet">
            <ProctorList />
          </Element>
          <Element header="Attendee Stuck" loading={false} emptyMessage="No events yet"></Element>
        </div>
        <div className="flex h-full w-4/5">
          <Element loading={false} emptyMessage="No data yet">
            <AttendeeStepTable
              participants={
                selectedSlotData && selectedSlotData.participant.length
                  ? selectedSlotData.participant
                  : []
              }
            />
          </Element>
        </div>
      </div>
    </div>
  );
};

export default MonitoringBoard;
