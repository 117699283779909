import { useState } from 'react';
import { CameraIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import Button from './Button';
import { ApprovalStatus, VerificationTypes } from '../../globals/enums';
import { getNotifyReasons } from 'helpers/prechecks';

type Props = {
  type: string;
  onClose: () => void;
  onNotify: (status: string, reason: string) => void;
};

const NotifyAttendee = ({ type, onClose, onNotify }: Props) => {
  const [rejectedReason, setRejectedReason] = useState('');

  const handleNotify = () => {
    onNotify(ApprovalStatus.Rejected, rejectedReason);
    onClose();
  };

  return (
    <div className="flex gap-4">
      <span className="w-10 h-10 bg-red-200 text-red-600 rounded-full flex p-2">
        {type === VerificationTypes.PhotoId ? <CameraIcon /> : <VideoCameraIcon />}
      </span>
      <div className="flex flex-col gap-4 mt-3">
        <p className="font-bold">Notify Candidate</p>
        <p className="text-zinc-500">
          You are notifying candidate to recapture their photo/ video. Choose a reason from the list
          or specify exactly of your expectation.
        </p>
        <select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setRejectedReason(e.target.value)}
          className="p-2 rounded-md border w-full"
        >
          <option value="" selected disabled>
            Select an option
          </option>
          {getNotifyReasons(type)?.map((reason) => (
            <option key={reason.key} value={reason.value}>
              {reason.text}
            </option>
          ))}
        </select>
        <div className="col-span-2 self-right flex gap-2 ml-auto">
          <Button className="secondary-btn" label="Cancel" onClick={() => onClose()} />
          <Button
            className="primary-btn"
            onClick={handleNotify}
            label="Notify"
            disabled={!rejectedReason}
          />
        </div>
      </div>
    </div>
  );
};

export default NotifyAttendee;
