import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import AudioCall from './AudioCall';
import ChatWindow from './ChatWindow';

type Props = {
  session: {
    attendee_id: number;
    session_id: string;
    session_uuid: string;
    messages: any;
    session_status: string;
  };
};

const ChatTab = ({ session }: Props) => {
  return (
    session?.session_id && (
      <div className="flex flex-col h-full w-full p-2 gap-2 overflow-auto">
        <div className="flex items-center">
          <div className="flex relative flex-grow">
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full rounded-md border-0 py-2 pl-2 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <MagnifyingGlassIcon className="h-6 w-6 " />
            </div>
          </div>
          <AudioCall
            session_status={session.session_status}
            session_id={session.session_id}
            session_uuid={session.session_uuid}
            attendee_id={session.attendee_id}
          />
        </div>
        <ChatWindow
          session_id={session.session_id}
          messages={session.messages}
          session_status={session.session_status}
        />
      </div>
    )
  );
};

export default ChatTab;
