import { createSlice } from '@reduxjs/toolkit';
import { TABS } from 'common/components/constant';

export const initialState = {
  sessions: [],
  isSuccess: false,
};

const sessionListSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    startSession: (state, { payload }) => {
      return state;
    },
    updateSession: (state, { payload }) => {
      state.sessions = payload;
      state.isSuccess = true;
      return state;
    },
  },
});

export const { startSession, updateSession } = sessionListSlice.actions;

export default sessionListSlice.reducer;
