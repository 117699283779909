import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { AUTO_ROTATE_INTERVAL } from 'common/components/constant';
import {
  CurrentFeed,
  updateMediaSetting,
  updateCurrentAutoRotateFeed,
  updateGloablMediaSettings,
} from 'common/slice/mediaSettingSlice';

const mediaSettingListener = createListenerMiddleware();

const state = {
  currentAutoIndex: 0,
  intervalId: null,
  autoRotateFeed: ['primary', 'secondary', 'screen-recording'],
};

const rotateFeeds = () => {
  state.currentAutoIndex = (state.currentAutoIndex + 1) % state.autoRotateFeed.length;
};

mediaSettingListener.startListening({
  matcher: isAnyOf(updateMediaSetting, updateGloablMediaSettings),
  effect: async (action, listenerApi: any) => {
    if (updateMediaSetting.match(action) || updateGloablMediaSettings.match(action)) {
      const { currentFeed } = action.payload;
      if (currentFeed === 'auto-rotate') {
        if (!state.intervalId) {
          state.intervalId = setInterval(() => {
            rotateFeeds();
            const nextActiveFeed = state.autoRotateFeed[state.currentAutoIndex];
            listenerApi.dispatch(
              updateCurrentAutoRotateFeed({
                currentAutoRotateFeed: nextActiveFeed as CurrentFeed,
              }),
            );
          }, AUTO_ROTATE_INTERVAL);
        }
      } else {
        const hasAutoRotateSettings =
          listenerApi
            .getState()
            .mediaSettings.mediaSettings.some((setting) => setting.currentFeed === 'auto-rotate') ||
          listenerApi.getState().mediaSettings.mediaSettings.currentFeed === 'auto-rotate';
        if (!hasAutoRotateSettings) {
          if (state.intervalId) {
            clearInterval(state.intervalId);
            state.intervalId = null;
          }
        }
      }
    }
  },
});

export default mediaSettingListener;
