import { useEffect, CSSProperties, useState } from 'react';
import useTwilioStream, { ParticipantData } from 'hooks/useTwilioStream';
import Tile from './Tile';
import { Attendee } from 'common/api/proctorType';
import AttendeePane from 'common/components/AttendeePane';
import AttendeeDetail from 'common/components/AttendeeDetail';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { addBreadcrumb } from '../../common/slice/breadcrumbsSlice';
import { updateActiveAttendee } from 'common/slice/selectedAttendeeSlice';
import { isApprovalRequired } from 'helpers/prechecks';
import { Precheck } from 'globals/interfaces';
import { hasUnreadMessages } from 'helpers/messages';
import { filter, orderBy } from 'lodash';
import { SessionStatus } from 'globals/enums';
import { setActiveAttendee } from 'common/slice/activeAttendeeSlice';

const TileViewContainer = ({ sessions, attendees, participant_group_id }) => {
  const dispatch = useAppDispatch();
  const { attendeeId, isDetailView } = useAppSelector((state) => state.selectedAttendee);
  const [availableHeight, setAvailableHeight] = useState(window.innerHeight - 60);
  const [availableWidth, setAvailableWidth] = useState((window.innerWidth * 7) / 12);

  const participants = useTwilioStream(participant_group_id);
  const participantsLength = sessions?.length;

  let rows = 2;
  let columns = 1;

  if (participantsLength >= 3 && participantsLength <= 4) {
    rows = 2;
    columns = 2;
  } else if (participantsLength >= 5 && participantsLength <= 9) {
    rows = 3;
    columns = 3;
  } else if (participantsLength >= 10 && participantsLength <= 16) {
    rows = 4;
    columns = 4;
  }

  const rowHight = availableHeight / rows - rows * 5;
  const columnsWidth = availableWidth / columns - columns * 5;

  const gridStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: `repeat(${rows}, ${rowHight}px)`,
    gridTemplateColumns: `repeat(${columns}, ${columnsWidth}px)`,
    gap: '5px',
    maxHeight: `${availableHeight}px`,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const gridItemStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: `${columnsWidth}px`,
    maxWidth: `${columnsWidth}px`,
    height: `${rowHight}px`,
    maxHeight: `${rowHight}px`,
    backgroundColor: '#0F172A',
  };

  useEffect(() => {
    const updateAvailableHeight = () => {
      setAvailableHeight(window.innerHeight - 60);
      setAvailableWidth((window.innerWidth * 7) / 12);
    };

    window.addEventListener('resize', updateAvailableHeight);

    return () => {
      window.removeEventListener('resize', updateAvailableHeight);
    };
  }, []);

  const handleTileClick = (attendee: Attendee) => {
    const payload = {
      label: attendee.attendee_number,
      url: '',
    };
    dispatch(addBreadcrumb(payload));
    dispatch(updateActiveAttendee({ attendeeId: attendee.attendee_id, isDetailView: true }));
    dispatch(setActiveAttendee(attendee));
  };

  const renderAttendeeDetailView = () => {
    const session = sessions.find((item) => item.attendee_id === attendeeId);
    if (session && session?.session_status !== SessionStatus.NoShow) {
      return (
        <AttendeeDetail
          participants={participants}
          attendee={attendees.find((attendee: Attendee) => attendee.attendee_id == attendeeId)}
          sessionId={sessions.find((item) => item.attendee_id === attendeeId)?.session_id}
          session={sessions.find((item) => item.attendee_id === attendeeId)}
        />
      );
    }

    return (
      <div className="h-full flex justify-center items-center">
        <p className="text-sm flex items-center gap-1 rounded py-2 px-3 bg-blue-500 text-white">
          {session?.session_status === SessionStatus.NoShow
            ? 'No Show'
            : 'Candidate has not joined yet'}
        </p>
      </div>
    );
  };

  const filterSessions = sessions?.length
    ? sessions.filter((session) => session.session_status !== SessionStatus.NoShow)
    : [];

  const sortedSessions = orderBy(filterSessions, ['attendee_id', ['asc']]);

  return attendeeId && isDetailView ? (
    renderAttendeeDetailView()
  ) : (
    <div style={gridStyle}>
      {sortedSessions.length &&
        sortedSessions.map((session: any, index) => {
          const participant = participants.find(
            (item: ParticipantData) => item.session_id === session.session_id,
          );
          const prechecks: Precheck[] = session.pre_check ? Object.values(session.pre_check) : [];
          const isApprovalPending = isApprovalRequired(prechecks);
          const attendee: Attendee = attendees.find(
            (attendee: Attendee) => attendee.attendee_id == session.attendee_id,
          );
          const messages = session?.messages ? Object.values(session.messages) : [];
          const isChatUnread = hasUnreadMessages(messages);
          return (
            <div
              key={session.attendee_id}
              style={gridItemStyle}
              onClick={() => handleTileClick(attendee)}
              data-testid={`tile_${index}`}
            >
              <div
                style={{ height: 'calc(100% - 24px)' }}
                className="flex w-full items-center justify-center"
              >
                <Tile
                  sessionStatus={session.session_status}
                  attendeeId={session.attendee_id}
                  participant={participant}
                  isApprovalPending={isApprovalPending}
                />
              </div>
              <div className="flex flex-row items-center justify-between w-full h-6 p-2 bg-blue-100">
                <AttendeePane
                  tooltipId={`tile_tooltip_${index}`}
                  isChatUnread={isChatUnread}
                  isApprovalPending={isApprovalPending}
                  attendee={attendee}
                  sessionId={session.session_id}
                  sessionUuid={session.session_uuid}
                  sessionStatus={session?.session_status}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TileViewContainer;
