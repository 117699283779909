import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';
import firebaseConfig from 'common/config/firebaseConfig';
import FirebaseProvider from 'common/components/FirebaseProvider';
import store from 'app/store';
import ProtectedRoute from 'common/components/ProtectedRoute';
import Slot from 'components/slot/Slot';
import AuthError from 'common/components/errors/AuthError';
import Login from 'components/login/Login';
import Dashboard from 'components/dashboard/dashboard/Dashboard';
import DashboardProvider from 'contexts/DashboardContext';
import MonitoringBoard from 'components/dashboard/dashboard/MonitoringBoard';
import { client as apolloClient } from 'common/utils/apollo';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<ProtectedRoute />}>
        <Route path="/slot" element={<Slot />} />
        <Route
          path="/dashboard"
          element={
            <DashboardProvider>
              <Dashboard />
            </DashboardProvider>
          }
        >
          <Route index element={<MonitoringBoard />} />
        </Route>
      </Route>
      <Route path="/">
        <Route index element={<Login />} />
        <Route path="auth-error" element={<AuthError />} />
      </Route>
    </>,
  ),
);

const App = () => {
  return (
    <Provider store={store}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <FirebaseProvider>
          <ApolloProvider client={apolloClient}>
            <RouterProvider router={router} />
          </ApolloProvider>
        </FirebaseProvider>
      </FirebaseAppProvider>
    </Provider>
  );
};

export default App;
