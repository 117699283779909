import { createSlice } from '@reduxjs/toolkit';
import { ActiveAttendee } from 'globals/interfaces';

interface ActiveAttendeeState {
  attendee: ActiveAttendee;
}

export const initialState: ActiveAttendeeState = {
  attendee: {
    attendee_id: null,
    attendee_external_id: '',
    attendee_first_name: '',
    attendee_last_name: '',
    attendee_number: '',
    attendee_email: '',
    slot_id: null,
    session: {
      id: '',
      uuid: '',
    },
    participant_group_id: null,
  },
};

const activeAttendeeSlice = createSlice({
  name: 'activeAttendee',
  initialState,
  reducers: {
    setActiveAttendee: (state, { payload }) => {
      const attendee = [payload].reduce((acc, attendee) => {
        acc = {
          ...acc,
          attendee_id: attendee.attendee_id,
          attendee_external_id: attendee.attendee_external_id,
          attendee_first_name: attendee.attendee_first_name,
          attendee_last_name: attendee.attendee_last_name,
          attendee_number: attendee.attendee_number,
          attendee_email: attendee.attendee_email,
          slot_id: attendee.slot_id,
          session: {
            id: attendee.session.id,
            uuid: attendee.session.uuid,
          },
          participant_group_id: attendee.participant_group_id,
        };
        return acc;
      }, {});

      state.attendee = { ...state.attendee, ...attendee };
    },
  },
});

export const { setActiveAttendee } = activeAttendeeSlice.actions;

export default activeAttendeeSlice.reducer;
