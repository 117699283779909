import { getDatabase, ref, set, push, update } from 'firebase/database';
import { formatDate } from './date';
import { USER_ROLES } from 'common/components/constant';
import { CallData } from 'common/slice/audioCallSlice';

export const markCandidateMessagesAsDeliveredInFirebase = (sessionData) => {
  if (!sessionData) {
    return;
  }
  const database = getDatabase();
  sessionData.forEach((session) => {
    if (session.messages) {
      Object.keys(session.messages).forEach((messageKey: any) => {
        const message = session.messages[messageKey];
        if (message.role === USER_ROLES.CANDIDATE && !message.received_at) {
          const messageRef = ref(
            database,
            `session_v2/${session.session_id}/messages/${messageKey}`,
          );
          update(messageRef, { received_at: new Date().toISOString() });
        }
      });
    }
  });
};

export const markCandidateMessagesAsReadInFirebase = (session_id: string, messages) => {
  if (!messages) {
    return;
  }
  const database = getDatabase();
  Object.keys(messages).forEach((messageKey: any) => {
    const message = messages[messageKey];
    if (message.role === USER_ROLES.CANDIDATE && !message.read_at) {
      const messageRef = ref(database, `session_v2/${session_id}/messages/${messageKey}`);
      update(messageRef, { read_at: new Date().toISOString() });
    }
  });
};

export const createNewMessage = async (session_id: string, messageData) => {
  const messageRef = ref(getDatabase(), `session_v2/${session_id}/messages/`);
  const newMessageRef = push(messageRef);
  await set(newMessageRef, messageData);
};

export const hasUnreadMessages = (messages) => {
  return messages.some((item) => item.read_at == '' && item.role == 'candidate');
};

export const getDeliveredStatusWithDateTime = (message) => {
  const { read_at, received_at, created_at, role } = message;

  if (role === USER_ROLES.CANDIDATE) {
    return formatDate(received_at);
  }

  return read_at
    ? `${formatDate(read_at)} | Read`
    : received_at
    ? `${formatDate(received_at)} | Delivered`
    : formatDate(created_at);
};

export const getAudioCallStatus = (
  session_id: string,
  audioCallData: Record<string, CallData>[],
) => {
  const audioCallSessionData = audioCallData?.[session_id];
  return audioCallSessionData?.status;
};

export const getAudioCallAtttendeeId = (
  session_id: string,
  audioCallData: Record<string, CallData>[],
) => {
  const audioCallSessionData = audioCallData?.[session_id];
  return audioCallSessionData?.attendee_id;
};

export const getActiveCallDetails = (audioCallData: Record<string, CallData>[]) => {
  // @ts-ignore
  const audioCallList: CallData[] = audioCallData ? Object.values(audioCallData) : [];
  return audioCallList.find((audioCall) => audioCall.status === 'connected');
};
