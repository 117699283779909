import { useAppSelector } from 'app/hooks';
import InfoCard from 'common/components/InfoCard';
import { getSortedPrechecks } from '../../helpers/prechecks';
import PrecheckWrapper from './PrecheckWrapper';

const ApprovalTab = ({ prechecks, session_id, session_uuid }) => {
  const attendee = useAppSelector((state) => state.activeAttendee.attendee);
  const sortedPrechecks = prechecks
    ? getSortedPrechecks(Object.values(prechecks))
    : {
        idChecks: null,
        envChecks: null,
      };

  return (
    <aside className="px-4 py-6 text-sm flex flex-col gap-4 overflow-auto">
      <InfoCard attendeeInfo={attendee} />
      <PrecheckWrapper
        prechecks={sortedPrechecks}
        session_uuid={session_uuid}
        session_id={session_id}
      />
    </aside>
  );
};

export default ApprovalTab;
