// import { Precheck } from 'globals/interfaces';
import { ApprovalStatus, VerificationTypes } from '../globals/enums';
import { PHOTO_REJECTED_REASONS, TEST_ENV_REJECT_REASONS } from 'common/components/constant';

export interface Precheck {
  uuid: string;
  file_path: string;
  reason: string;
  reference_step_uuid?: string;
  requested_timestamp: string;
  reviewed_timestamp: string;
  status: string;
  step: string;
  faceCaptureUrl?: string;
}

export const getSortedPrechecks = (prechecks: Precheck[]) => {
  const arrangedPrechecks = prechecks.reduce(
    (acc, item: Precheck) => {
      if (item.status !== ApprovalStatus.Stale) {
        if (item.step === VerificationTypes.PhotoId) {
          const faceCaptureUrl =
            prechecks.find(
              (face_capture: Precheck) => face_capture.uuid === item.reference_step_uuid,
            )?.file_path || '';
          const updatedItem = { ...item, faceCaptureUrl };
          if (updatedItem.faceCaptureUrl) acc.idChecks.unshift(updatedItem);
        } else if (item.step === VerificationTypes.TestEnv) {
          acc.envChecks.unshift(item);
        }
      }
      return acc;
    },
    { idChecks: [], envChecks: [] },
  );

  return arrangedPrechecks;
};

export const getNotifyReasons = (type: string) => {
  switch (type) {
    case VerificationTypes.PhotoId:
      return PHOTO_REJECTED_REASONS;
    case VerificationTypes.TestEnv:
      return TEST_ENV_REJECT_REASONS;
    default:
      break;
  }
};

export const getSignedUrl = (session_uuid: string, file_path: string, token: string) => {
  return `${process.env.AZURE_BLOB_URL}/${session_uuid}/${file_path}?${token}`;
};

export const isApprovalRequired = (prechecks: Precheck[]) => {
  const hasItem = (step: string) =>
    prechecks?.some((item) => item.status === ApprovalStatus.Submitted && item.step === step);

  const photoIdIsPresent = hasItem(VerificationTypes.PhotoId);
  const faceCaptureIsPresent = hasItem(VerificationTypes.FaceCapture);
  const environmentCheckPresent = hasItem(VerificationTypes.TestEnv);

  return (photoIdIsPresent && faceCaptureIsPresent) || environmentCheckPresent;
};
