import { memo, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import getGroupedAlerts from 'helpers/alerts';
import AlertList from './AlertList';
import { useAppDispatch } from 'app/hooks';
import { openModal } from 'common/slice/modalSlice';
import { SessionStatus } from 'globals/enums';

interface Alert {
  alert_type_id: number;
  description: string;
  ip_address: string;
  referring_url: string;
  updated_at: string;
  updated_by: string;
  user_agent: string;
}

const AlertTab = memo(({ alerts, attendeeId, sessionUuid, sessionId, sessionStatus }: any) => {
  const dispatch = useAppDispatch();
  const [inputText, setInputText] = useState('');
  const [isType, setIsType] = useState(true);
  const alertsList = getGroupedAlerts(alerts, isType, inputText.trim());
  const handleAddFlag = () => {
    dispatch(
      openModal({
        attendeeId,
        type: 'add-flag',
        sessionUuid,
        sessionId,
      }),
    );
  };
  return (
    sessionId &&
    sessionStatus !== SessionStatus.NoShow && (
      <div className="flex flex-col w-full overflow-auto">
        <div className="relative p-3 flex items-center">
          <input
            type="text"
            name="search"
            id="search"
            className="block w-full rounded-md border-0 py-2 pl-2 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
            onChange={(e) => setInputText(e.target.value)}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <MagnifyingGlassIcon className="h-6 w-6" />
          </div>
        </div>
        <div className="flex p-3 justify-between items-center align-middle">
          <div className="flex">
            <div className="flex items-center">Group by</div>
            <div className="flex items-center ml-2">
              <input
                id="type"
                name="alert-group"
                type="radio"
                checked={isType}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onClick={() => setIsType(true)}
              />
              <label className="ml-1 block text-sm font-medium leading-6 text-gray-900">Type</label>
            </div>
            <div className="flex items-center ml-3">
              <input
                id="severity"
                name="alert-group"
                type="radio"
                checked={!isType}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onClick={() => setIsType(false)}
              />
              <label className="ml-1 block text-sm font-medium leading-6 text-gray-900">
                Severity
              </label>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="rounded-md  px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              style={{ backgroundColor: ' #2563EB' }}
              onClick={() => handleAddFlag()}
            >
              Add Flag
            </button>
          </div>
        </div>
        <AlertList isType={isType} alertsList={alertsList} />
      </div>
    )
  );
});

export default AlertTab;
