import React, { useContext } from 'react';
import { ReactFireOptions, useDatabase, useDatabaseListData } from 'reactfire';
import { equalTo, orderByChild, query, ref } from 'firebase/database';
import ProgressBar from 'common/components/ProgressBar';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';
import { countBy } from 'lodash';
import Loading from '../../../../common/components/Loading';

type Props = {
  invited: number;
};

const AttendeeStatus: React.FC<Props> = (props) => {
  const database = useDatabase();
  const [attendeeStatus, setAttendeeStatus] = React.useState<Record<string, number>>({});
  const { slots, setSessions } = useContext<DashboardContextType>(DashboardContext);
  const selectedSlot = slots.find((slot) => slot.selected);

  const options: ReactFireOptions = {
    idField: 'session_id',
  };
  const deviceQuery = query(
    ref(database, 'session_v2'),
    orderByChild('slot_id'),
    equalTo(selectedSlot.id),
  );
  const { data, error, status } = useDatabaseListData(deviceQuery, options);
  React.useEffect(() => {
    if (data) {
      setSessions(data);
      const groupByStatus = countBy(data, 'session_status');

      setAttendeeStatus(groupByStatus);
    }
  }, [data]);
  const startedTest =
    (attendeeStatus.offline || 0) + (attendeeStatus.online || 0) + (attendeeStatus.created || 0);
  const completed = attendeeStatus.completed || 0;
  const terminated = attendeeStatus.terminated || 0;
  const notJoined = props.invited - startedTest - completed - terminated;
  const getPercentage = (value: number) => {
    return (value / props.invited) * 100;
  };

  if (status === 'loading') return <Loading />;

  return (
    <div className="flex flex-col space-y-6 p-2">
      <ProgressBar
        label="Not Joined"
        color="red"
        value={notJoined}
        percentage={getPercentage(notJoined)}
      />
      <ProgressBar
        label="Started Test"
        color="blue"
        value={startedTest}
        percentage={getPercentage(startedTest)}
      />
      <ProgressBar
        label="Finished Test"
        color="blue"
        value={completed}
        percentage={getPercentage(completed)}
      />
      <ProgressBar
        label="Terminated"
        color="red"
        value={terminated}
        percentage={getPercentage(terminated)}
      />
    </div>
  );
};

export default AttendeeStatus;
