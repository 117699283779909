import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { AuthProvider, DatabaseProvider, useFirebaseApp } from 'reactfire';

function FirebaseProvider({ children }) {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const database = getDatabase(app);

  return (
    <AuthProvider sdk={auth}>
      <DatabaseProvider sdk={database}>{children}</DatabaseProvider>
    </AuthProvider>
  );
}

export default FirebaseProvider;
