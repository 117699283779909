import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from './base';

const sessionApi = createApi({
  reducerPath: 'sessionApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    updateStatus: builder.mutation({
      query: (payload) => ({
        url: `/v1/session/status`,
        method: 'PATCH',
        body: payload,
      }),
    }),
  }),
});

export const { useUpdateStatusMutation } = sessionApi;

export default sessionApi;
