import React from 'react';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
};

const Input: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-col w-full text-sm space-y-0.5">
      <div className="font-medium">{props.label}</div>
      <input {...props} className="w-full rounded outline-none px-2 py-1 border border-slate-300" />
    </div>
  );
};

export default Input;
