import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const AuthError = () => {
  return (
    <div className="fixed inset-0">
      <div className="flex flex-col justify-center gap-3 items-center h-full">
        <h1 className="text-4xl font-bold text-gray-800">
          <ExclamationTriangleIcon className="w-20 h-20 text-center mx-auto my-2 text-red-600" />
          Authentication Error
        </h1>
        <p className="text-gray-600">You are not authorized to view this page.</p>
        <p className="text-gray-600">
          click here to{' '}
          <a className="text-blue-400" href="https://live-proctor-v2.talview.org/login">
            log in
          </a>
        </p>
      </div>
    </div>
  );
};

export default AuthError;
