import React from 'react';
import { closeModal } from 'common/slice/modalSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Modal from './Modal';
import NoShowModal from 'components/noshow/NoShowModal';
import TerminateModal from 'components/terminate/TerminateModal';
import { SessionStatus } from 'globals/enums';
import AddFlag from 'components/alerts/AddFlag';

const ActionModal = () => {
  const dispatch = useAppDispatch();
  const { type, isOpen, attendeeId, sessionUuid, sessionId } = useAppSelector(
    (state) => state.modal,
  );

  const renderMessage = () => {
    switch (type) {
      case SessionStatus.NoShow:
        return <NoShowModal attendeeId={attendeeId} />;
      case SessionStatus.Terminated:
        return <TerminateModal sessionId={sessionId} />;
      case 'add-flag':
        return <AddFlag sessionUuid={sessionUuid} />;
      default:
        break;
    }
  };

  return (
    <Modal show={isOpen} onCancel={() => dispatch(closeModal({}))}>
      {renderMessage()}
    </Modal>
  );
};

export default ActionModal;
