import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { setupListeners } from '@reduxjs/toolkit/query';
import proctorApi from 'common/api/proctor';
import authApi from 'common/api/auth';
import precheckApi from 'common/api/precheck';
import sasTokenApi from 'common/api/sasToken';
import alertApi from 'common/api/alert';
import audioCallTokenApi from 'common/api/audioCallToken';
import sessionApi from 'common/api/session';
import activeAttendee from 'common/slice/activeAttendeeSlice';
import activeTab from 'common/slice/activeTabSlice';
import mediaSettings from 'common/slice/mediaSettingSlice';
import audioSettings from 'common/slice/audioSettingSlice';
import session from 'common/slice/SessionSlice';
import breadcrumbs from 'common/slice/breadcrumbsSlice';
import selectedAttendee from 'common/slice/selectedAttendeeSlice';
import audioCall from 'common/slice/audioCallSlice';
import currentUser from 'common/slice/currentUserSlice';
import modal from 'common/slice/modalSlice';
import audioCallListener from 'common/listener/audioCallListener';
import rtkQueryErrorLogger from 'common/middlewares/rtkQueryErrorLogger';
import mediaSettingListener from 'common/listener/mediaSettingsListener';
import sessionList from 'common/slice/sessionListSlice';
import sessionCallListener from 'common/listener/sessionCallListener';

const middlewares = [
  proctorApi.middleware,
  authApi.middleware,
  precheckApi.middleware,
  sasTokenApi.middleware,
  alertApi.middleware,
  audioCallTokenApi.middleware,
  audioCallListener.middleware,
  mediaSettingListener.middleware,
  sessionApi.middleware,
  sessionCallListener.middleware,
  rtkQueryErrorLogger,
];

const store = configureStore({
  reducer: {
    sessionList,
    activeAttendee,
    activeTab,
    mediaSettings,
    audioSettings,
    breadcrumbs,
    session,
    selectedAttendee,
    audioCall,
    currentUser,
    modal,
    [proctorApi.reducerPath]: proctorApi.reducer,
    [alertApi.reducerPath]: alertApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [precheckApi.reducerPath]: precheckApi.reducer,
    [sasTokenApi.reducerPath]: sasTokenApi.reducer,
    [audioCallTokenApi.reducerPath]: audioCallTokenApi.reducer,
    [sessionApi.reducerPath]: sessionApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
