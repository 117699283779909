import Tile from 'components/tiles/Tile';
import AttendeePane from './AttendeePane';
import { Attendee } from 'common/api/proctorType';
import { ParticipantData } from 'hooks/useTwilioStream';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getSignedUrl, isApprovalRequired } from 'helpers/prechecks';
import { formatDate } from 'helpers/date';
import { Precheck } from 'globals/interfaces';
import { hasUnreadMessages } from 'helpers/messages';
import { isSessionEnded } from 'helpers/session';
import { openModal } from 'common/slice/modalSlice';
import { SessionStatus } from 'globals/enums';

interface Props {
  participants: ParticipantData[];
  session: any;
  sessionId: string;
  attendee: Attendee;
}

const AttendeeDetail = ({ sessionId, session, attendee, participants }: Props) => {
  const participant = participants.find((item: ParticipantData) => item.session_id === sessionId);
  const token: string = useAppSelector((state) => state.session?.token)[sessionId]?.value;
  const dispatch = useAppDispatch();
  const prechecks: Precheck[] = session?.pre_check ? Object.values(session?.pre_check) : [];

  const isApprovalPending = isApprovalRequired(prechecks);
  const screenGrabsAndCameraGrabs: any[] = session?.session_files
    ? Object.values(session.session_files)
    : [];
  const messages = session?.messages ? Object.values(session.messages) : [];
  const isChatUnread = hasUnreadMessages(messages);

  const handleTerminate = () => {
    dispatch(
      openModal({
        attendeeId: attendee.attendee_id,
        type: SessionStatus.Terminated,
        sessionUuid: session.session_uuid,
        sessionId: session.session_id,
      }),
    );
  };

  const handleAddFlag = () => {
    dispatch(
      openModal({
        attendeeId: attendee.attendee_id,
        type: 'add-flag',
        sessionUuid: session.session_uuid,
        sessionId: session.session_id,
      }),
    );
  };

  return (
    <div className="h-screen flex flex-col gap-2">
      <div
        className="flex flex-col relative justify-center items-center h-1/2 max-h-1/2 bg-gray-300"
        style={{ backgroundColor: '#0F172A' }}
      >
        <div style={{ height: 'calc(100% - 24px)' }} className="flex items-center justify-center">
          <Tile
            sessionStatus={session.session_status}
            attendeeId={attendee.attendee_id}
            isApprovalPending={isApprovalPending}
            participant={participant}
          />
        </div>
        <div className="flex flex-row items-center justify-between w-full h-6 p-4 bg-blue-100">
          <AttendeePane
            tooltipId={`detail_tooltip_${attendee.attendee_id}`}
            isChatUnread={isChatUnread}
            isApprovalPending={isApprovalPending}
            attendee={attendee}
            sessionId={sessionId}
            sessionUuid={session.session_uuid}
            sessionStatus={session?.session_status}
          />
        </div>
      </div>
      <div className=" flex h-1/10 gap-2  p-2 justify-end">
        <button
          disabled={isSessionEnded(session.session_status)}
          type="button"
          className="rounded-md  bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
          onClick={() => handleTerminate()}
        >
          Terminate
        </button>
        <button
          type="button"
          className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          onClick={() => handleAddFlag()}
        >
          Add flag
        </button>
      </div>
      <div className="h-2/5 att-det-image-container">
        {screenGrabsAndCameraGrabs?.reverse().map((file, index) => {
          return (
            <div className="flex flex-col items-center align-middle" key={index}>
              <img src={getSignedUrl(session.session_uuid, file.path, token)} />
              <div>{formatDate(file.timestamp)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AttendeeDetail;
