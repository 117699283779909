import useAzureBlob from 'hooks/useAzureBlob';
import { useFetchTwilioTokenQuery } from 'common/api/audioCallToken';
import { Attendee } from 'common/api/proctorType';
import RightPanel from 'common/components/RightPanel';
import TileViewContainer from 'components/tiles/TileViewContainer';
import AudioCallWidget from 'components/chat/AudioCallWidget';
import { markCandidateMessagesAsDeliveredInFirebase } from 'helpers/messages';
import { useAppSelector } from 'app/hooks';
import Loader from 'common/components/Loader';

interface Props {
  participant_group_id: number;
  attendees: Attendee[];
}

const Session = ({ participant_group_id, attendees }: Props) => {
  const { sessions, isSuccess } = useAppSelector((state) => state.sessionList);
  useAzureBlob(sessions);
  const { data: twilioToken } = useFetchTwilioTokenQuery({});
  markCandidateMessagesAsDeliveredInFirebase(sessions);

  return isSuccess ? (
    <div className="flex flex-row flex-grow bg-black-50 overflow-hidden">
      <div className="flex w-7/12 relative">
        <div className="absolute left-3 right-3 top-3 bottom-3">
          <TileViewContainer
            sessions={sessions}
            attendees={attendees}
            participant_group_id={participant_group_id}
          />
        </div>
      </div>
      <div className="flex w-5/12 bg-white flex-grow border-l-2">
        <RightPanel sessions={sessions} attendees={attendees} />
        {twilioToken && <AudioCallWidget attendees={attendees} />}
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Session;
