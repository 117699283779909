import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CurrentFeed = 'primary' | 'secondary' | 'screen-recording' | 'auto-rotate';

export interface SelectedAttendee {
  attendeeId: number;
  isDetailView: boolean;
}

const initialState: SelectedAttendee = {
  attendeeId: 0,
  isDetailView: false,
};

const activeAttendeeSlice = createSlice({
  name: 'activeAttendee',
  initialState,
  reducers: {
    updateActiveAttendee: (state, action: PayloadAction<SelectedAttendee>) => {
      const { attendeeId, isDetailView } = action.payload;
      return { ...state, attendeeId, isDetailView };
    },
  },
});

export const { updateActiveAttendee } = activeAttendeeSlice.actions;
export default activeAttendeeSlice.reducer;
