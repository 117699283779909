import React from 'react';

type ButtonProps = {
  label?: string | React.ReactNode;
  className: string;
  onClick?: () => void;
  type?: string;
  disabled?: boolean;
  name?: string;
  icon?: React.ReactNode;
  isLoading?: boolean;
};

const Button = (props: ButtonProps) => {
  const { label, className, onClick, type, disabled, icon, isLoading, ...rest } = props;
  return (
    <button className={className} onClick={onClick} disabled={isLoading || disabled} {...rest}>
      {icon}
      {isLoading ? (
        <span className="flex justify-center items-center" role="spinbutton">
          <span className="h-4 w-4 border-2 animate-spin rounded-full border-r-transparent border-inherit"></span>
        </span>
      ) : (
        label
      )}
    </button>
  );
};

export default Button;
