import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CurrentFeed = 'primary' | 'secondary' | 'screen-recording' | 'auto-rotate';

export interface MediaSetting {
  attendeeId: number;
  currentFeed: CurrentFeed;
}

export interface AutoRotateFeed {
  currentAutoRotateFeed: CurrentFeed;
}

export interface MediaSettings {
  currentFeed: CurrentFeed;
  currentAutoRotateFeed: CurrentFeed;
  mediaSettings: MediaSetting[];
}

const initialState: MediaSettings = {
  currentFeed: 'primary',
  currentAutoRotateFeed: 'primary',
  mediaSettings: [],
};

const mediaSettingsSlice = createSlice({
  name: 'mediaSettings',
  initialState,
  reducers: {
    updateMediaSetting: (state, action: PayloadAction<MediaSetting>) => {
      const { attendeeId, currentFeed } = action.payload;

      const existingSessionIndex = state.mediaSettings.findIndex(
        (s) => s.attendeeId === attendeeId,
      );
      const updatedSession: MediaSetting = {
        attendeeId,
        currentFeed,
      };

      if (existingSessionIndex === -1) {
        state.mediaSettings = [...state.mediaSettings, updatedSession];
      } else {
        state.mediaSettings = state.mediaSettings.map((session, index) => {
          if (index === existingSessionIndex) {
            return { ...session, ...updatedSession };
          }
          return session;
        });
      }
    },
    updateCurrentAutoRotateFeed: (state, action: PayloadAction<AutoRotateFeed>) => {
      const { currentAutoRotateFeed } = action.payload;
      return { ...state, currentAutoRotateFeed: currentAutoRotateFeed };
    },
    updateGloablMediaSettings: (
      state,
      action: PayloadAction<{
        attendeeIds: 'all' | number[];
        currentFeed: CurrentFeed;
      }>,
    ) => {
      const { attendeeIds, currentFeed } = action.payload;

      if (attendeeIds === 'all') {
        return {
          ...state,
          mediaSettings: [],
          currentFeed: currentFeed,
        };
      } else {
        const updatedSettings = state.mediaSettings.map((existingSetting) => {
          if (attendeeIds.includes(existingSetting.attendeeId)) {
            return {
              ...existingSetting,
              currentFeed: currentFeed,
            };
          }
          return existingSetting;
        });

        attendeeIds.forEach((attendeeId) => {
          if (!updatedSettings.some((setting) => setting.attendeeId === attendeeId)) {
            updatedSettings.push({ attendeeId, currentFeed: currentFeed });
          }
        });

        return { ...state, mediaSettings: updatedSettings };
      }
    },
  },
});

export const { updateMediaSetting, updateCurrentAutoRotateFeed, updateGloablMediaSettings } =
  mediaSettingsSlice.actions;
export default mediaSettingsSlice.reducer;
