import { gql } from '@apollo/client';

export const GET_SLOTS = gql`
  query GetSlots($startsAt: timestamptz!, $endsAt: timestamptz!) {
    views_slot(
      where: {
        starts_at: { _gte: $startsAt }
        _and: { ends_at: { _lt: $endsAt }, _and: { status: { _in: ["scheduled", "live"] } } }
      }
    ) {
      id
      starts_at
      ends_at
      status
      event {
        id
        name
      }
      participant {
        id
        participant_group_id
        role
        user_id
      }
    }
  }
`;

export const GET_PARTICIPANT_DETAILS = gql`
  query GetParticipantDetails($proctorIds: [Int!]!, $attendeeIds: [Int!]!) {
    views_user(where: { id: { _in: $proctorIds } }) {
      id
      first_name
      last_name
      username
      email
    }
    attendee(where: { id: { _in: $attendeeIds } }) {
      id
      first_name
      last_name
      email
      external_attendee_id
    }
  }
`;
