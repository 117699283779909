import { useEffect } from 'react';
import { useGetSlotDetailQuery } from 'common/api/proctor';
import { formattedDateTime, formattedTime } from 'common/utils/DateTimeUtils';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { addBreadcrumb } from 'common/slice/breadcrumbsSlice';
import { updateActiveAttendee } from 'common/slice/selectedAttendeeSlice';
import { setActiveAttendee } from 'common/slice/activeAttendeeSlice';
import Header from 'common/components/Header';
import Loader from 'common/components/Loader';
import Session from './Session';
import ActionModal from 'common/components/ActionModal';
import { startSession } from 'common/slice/sessionListSlice';

const SlotDetail = () => {
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = +urlParams.get('event_id');
  const slot_id = +urlParams.get('slot_id');

  const { data, isSuccess, isLoading } = useGetSlotDetailQuery({
    event_id,
    slot_id,
  });

  useEffect(() => {
    if (data) {
      const url = `/slot?event_id=${event_id}&slot_id=${slot_id}`;
      const label = `${formattedDateTime(data?.slot_starts_at)} | ${formattedTime(
        data?.slot_starts_at,
      )} - ${formattedTime(data?.slot_ends_at)}`;
      const payload = {
        label,
        url,
      };
      dispatch(addBreadcrumb(payload));
      const defaultAttendee = data.attendees[0];
      if (defaultAttendee) {
        dispatch(
          updateActiveAttendee({
            attendeeId: defaultAttendee.attendee_id,
            isDetailView: false,
          }),
        );
        dispatch(setActiveAttendee(defaultAttendee));
      }
      dispatch(startSession(data.participant_group_id));
    }
  }, [data]);

  return (
    <>
      {isLoading && <Loader />}
      {isSuccess && (
        <div className="flex flex-col w-screen h-screen">
          <Header />
          <Session attendees={data.attendees} participant_group_id={data.participant_group_id} />
          <ActionModal />
        </div>
      )}
    </>
  );
};

export default SlotDetail;
