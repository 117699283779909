import React from 'react';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import Loading from '../../../common/components/Loading';
import EmptyState from '../../../common/components/EmptyState';

type Props = React.PropsWithChildren & {
  header?: React.ReactNode;
  loading?: boolean;
  emptyMessage: string;
};

const Element: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-col rounded w-full h-full bg-white p-2 border border-solid border-gray-300 space-y-1">
      {props.header && (
        <div className="flex flex-col justify-center w-full border-b border-gray-200 pb-2">
          <div className="flex w-full space-x-1 items-center">
            <div className={`text-xs font-semibold`}>{props.header}</div>
          </div>
        </div>
      )}
      <div className="flex flex-col w-full overflow-y-scroll">
        {props.loading && <Loading />}
        {!props.loading && !props.children && (
          <EmptyState
            icon={<CalendarDaysIcon className="h-10 w-10" />}
            description={props.emptyMessage}
          />
        )}
        {!props.loading && props.children}
      </div>
    </div>
  );
};

export default Element;
