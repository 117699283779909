import {
  ComputerDesktopIcon,
  CameraIcon,
  ArrowPathRoundedSquareIcon,
  DevicePhoneMobileIcon,
} from '@heroicons/react/24/outline';

export const TABS = {
  ALERT_LOGS: 'Alert Logs',
  CHAT: 'Chat',
  APPROVALS: 'Approvals',
  SUMMARY: 'Summary',
};

export const PHOTO_REJECTED_REASONS = [
  {
    key: "Photo ID doesn't match with the photo captured",
    value: "Photo ID doesn't match with the photo captured",
    text: "Photo ID doesn't match with the photo captured",
    verification_type: 'photo_id',
  },
  {
    key: 'Invalid ID card used for authentication',
    value: 'Invalid ID card used for authentication',
    text: 'Invalid ID card used for authentication',
    verification_type: 'photo_id',
  },
  {
    key: 'The photo on the ID card is not visible/blurred',
    value: 'The photo on the ID card is not visible/blurred',
    text: 'The photo on the ID card is not visible/blurred',
    verification_type: 'photo_id',
  },
];

export const AUTO_ROTATE_INTERVAL = 10000;

export const TEST_ENV_REJECT_REASONS = [
  {
    key: "Full 360° view is 'not' shown seamlessly without any part being skipped or hurried through",
    value:
      "Full 360° view is 'not' shown seamlessly without any part being skipped or hurried through",
    text: "Full 360° view is 'not' shown seamlessly without any part being skipped or hurried through",
    verification_type: 'test_env',
  },
  {
    key: 'Another computer is present in the room',
    value: 'Another computer is present in the room',
    text: 'Another computer is present in the room',
    verification_type: 'test_env',
  },
  {
    key: 'Another person present in the room, despite asking for the candidate to be alone',
    value: 'Another person present in the room, despite asking for the candidate to be alone',
    text: 'Another person present in the room, despite asking for the candidate to be alone',
    verification_type: 'test_env',
  },
  {
    key: 'The camera remained covered during check',
    value: 'The camera remained covered during check',
    text: 'The camera remained covered during check',
    verification_type: 'test_env',
  },
  {
    key: "Didn't show allowed materials on camera",
    value: "Didn't show allowed materials on camera",
    text: "Didn't show allowed materials on camera",
    verification_type: 'test_env',
  },
  {
    key: 'Deskphone not disconnected',
    value: 'Deskphone not disconnected',
    text: 'Deskphone not disconnected',
    verification_type: 'test_env',
  },
  {
    key: 'Clean whiteboard if present in the room',
    value: 'Clean whiteboard if present in the room',
    text: 'Clean whiteboard if present in the room',
    verification_type: 'test_env',
  },
  {
    key: 'Presence of mobile phone',
    value: 'Presence of mobile phone',
    text: 'Presence of mobile phone',
    verification_type: 'test_env',
  },
  {
    key: 'Shared desktop view',
    value: 'Shared desktop view',
    text: 'Shared desktop view',
    verification_type: 'test_env',
  },
  {
    key: 'Headsets or earphones present',
    value: 'Headsets or earphones present',
    text: 'Headsets or earphones present',
    verification_type: 'test_env',
  },
  {
    key: 'Improper lighting',
    value: 'Improper lighting',
    text: 'Improper lighting',
    verification_type: 'test_env',
  },
];

export const USER_ROLES = {
  CANDIDATE: 'candidate',
  PROCTOR: 'proctor',
  TEST_ADMIN: 'test-admin',
};

export const SEVERITY = {
  critical: '#dc2626',
  high: '#faCC15',
  medium: '#64748b',
  low: '#d4b8b8',
  none: '#82e1d7',
};

export const mediaSettingsIcons = [
  { Icon: CameraIcon, title: 'Primary camera', value: 'primary' },
  { Icon: ComputerDesktopIcon, title: 'Screen recording', value: 'screen-recording' },
  { Icon: DevicePhoneMobileIcon, title: 'Secondary camera', value: 'secondary' },
  { Icon: ArrowPathRoundedSquareIcon, title: 'Auto rotate', value: 'auto-rotate' },
];

export const flagReasons = [
  {
    key: 1,
    text: 'Window Focus-Out',
    altert_type_id: 5,
  },
  {
    key: 2,
    text: 'Face Not Detected',
    altert_type_id: 16,
  },
  {
    key: 3,
    text: 'Camera Not Detected',
    altert_type_id: 59,
  },
  {
    key: 4,
    text: 'Multiple faces Detected',
    altert_type_id: 81,
  },
  {
    key: 5,
    text: 'Pasting text detected',
    altert_type_id: 128,
  },
  {
    key: 6,
    text: 'Camera switched',
    altert_type_id: 130,
  },
  {
    key: 7,
    text: 'Microphone switched',
    altert_type_id: 131,
  },
  {
    key: 8,
    text: 'No browsing of other files on computer / Did not use messaging apps while attempting the test',
    altert_type_id: 137,
  },
  {
    key: 9,
    text: 'Use of unauthorized devices',
    altert_type_id: 137,
  },
  {
    key: 10,
    text: 'If reading questions, was loud enough for others in the room to hear',
    altert_type_id: 137,
  },
  {
    key: 11,
    text: "The student doesn't stay in the same room and location as was validated during the 360° check",
    altert_type_id: 137,
  },
  {
    key: 12,
    text: 'Displayed prohibited behavior (explicit/obscene) Candidate indulging in activities which are improper from a test behavior perspective.',
    altert_type_id: 137,
  },
  {
    key: 13,
    text: 'Attempted to take screenshots',
    altert_type_id: 137,
  },
  {
    key: 14,
    text: 'Used smartphone/tablet to take pictures of the test screen',
    altert_type_id: 137,
  },
  {
    key: 15,
    text: 'Improper lighting',
    altert_type_id: 137,
  },
];
