import { FlagIcon } from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useAddAlertMutation } from 'common/api/alert';
import { flagReasons } from 'common/components/constant';
import { closeModal } from 'common/slice/modalSlice';
import { formatDate } from 'helpers/date';
import { useState } from 'react';

const AddFlag = ({ sessionUuid }) => {
  const date = new Date().toISOString();
  const dispatch = useAppDispatch();
  const [addAlert] = useAddAlertMutation();
  const [flagValue, setFlagValue] = useState('');

  const handleAddFlagClick = async () => {
    dispatch(closeModal({}));
    const flag = flagReasons.find((a) => a.altert_type_id === parseInt(flagValue));
    await addAlert({
      alert_type_id: flag.altert_type_id,
      description: flag.text,
      session_id: sessionUuid,
      timestamp: date,
    });
  };
  const canceModal = () => {
    dispatch(closeModal({}));
  };
  return (
    <div className="flex gap-2">
      <span className="w-10 h-10 bg-blue-200 text-blue-800 rounded-full flex p-2">
        <FlagIcon />
      </span>
      <div className="flex flex-col gap-2 text-sm">
        <div className="mt-1.5 font-bold text-lg">Add Flag</div>
        <div className="text-zinc-500">Event Occured: {formatDate(date)}</div>
        <div>
          <select
            className="p-2 rounded-md border w-full"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFlagValue(e.target.value)}
          >
            <option className="flex break-words rounded">Select an option</option>
            {flagReasons.map((flag) => (
              <option key={flag.key} value={flag.altert_type_id}>
                {flag.text}
              </option>
            ))}
          </select>
        </div>
        <div className="text-sm flex justify-end gap-3 mt-5">
          <button className="secondary-btn" onClick={canceModal}>
            Cancel
          </button>
          <button className="primary-btn" disabled={!flagValue} onClick={handleAddFlagClick}>
            Add flag
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFlag;
